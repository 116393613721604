import React from 'react';
import { Card, Button } from 'antd';
import { gradeNameSort } from '../Util';

class AssessmentCountView extends React.Component {
    state = {
        expandedSchools: new Set(),
        expandedSubjects: new Set(),
        expandedGrades: new Set(),
        selectedCategory: 'all'
    };

    calculateStats() {
        const { assessmentData } = this.props;
        const { selectedCategory } = this.state;
        const stats = {};

        const filteredAssessments = assessmentData.filter(assessment => {
            if (selectedCategory === 'all') return true;
            return assessment.metadata?.assessmentCategory === selectedCategory;
        });

        filteredAssessments.forEach(assessment => {
            const quarter = this.getQuarter(assessment.metadata?.endWeek);

            assessment.schools?.forEach(school => {
                if (!stats[school.schoolId]) {
                    stats[school.schoolId] = {
                        name: school.schoolName || 'Unknown School',
                        subjects: {},
                        counts: { Q1: 0, Q2: 0, Q3: 0, Q4: 0, total: 0 }
                    };
                }

                school.subjects?.forEach(subject => {
                    if (!stats[school.schoolId].subjects[subject.name]) {
                        stats[school.schoolId].subjects[subject.name] = {
                            name: subject.name,
                            grades: {},
                            counts: { Q1: 0, Q2: 0, Q3: 0, Q4: 0, total: 0 }
                        };
                    }

                    subject.grades?.forEach(grade => {
                        const gradeKey = grade.name;
                        if (!stats[school.schoolId].subjects[subject.name].grades[gradeKey]) {
                            stats[school.schoolId].subjects[subject.name].grades[gradeKey] = {
                                name: grade.name,
                                teachers: {},
                                counts: { Q1: 0, Q2: 0, Q3: 0, Q4: 0, total: 0 }
                            };
                        }

                        const gradeStats = stats[school.schoolId].subjects[subject.name].grades[gradeKey];

                        grade.teachers?.forEach(teacher => {
                            if (!gradeStats.teachers[teacher.teacherId]) {
                                gradeStats.teachers[teacher.teacherId] = {
                                    teacherId: teacher.teacherId,
                                    counts: { Q1: 0, Q2: 0, Q3: 0, Q4: 0, total: 0 }
                                };
                            }

                            gradeStats.teachers[teacher.teacherId].counts[quarter]++;
                            gradeStats.teachers[teacher.teacherId].counts.total++;
                            gradeStats.counts[quarter]++;
                            gradeStats.counts.total++;
                            stats[school.schoolId].subjects[subject.name].counts[quarter]++;
                            stats[school.schoolId].subjects[subject.name].counts.total++;
                            stats[school.schoolId].counts[quarter]++;
                            stats[school.schoolId].counts.total++;
                        });
                    });
                });
            });
        });

        return Object.values(stats).sort((a, b) => a.name.localeCompare(b.name));
    }

    getQuarter(week) {
        if (week <= 9) return 'Q1';
        if (week <= 18) return 'Q2';
        if (week <= 27) return 'Q3';
        return 'Q4';
    }

    handleCategoryChange = (category) => {
        this.setState({ selectedCategory: category });
    };

    toggleSchool = (schoolName) => {
        this.setState(prevState => ({
            expandedSchools: new Set(
                prevState.expandedSchools.has(schoolName)
                    ? Array.from(prevState.expandedSchools).filter(name => name !== schoolName)
                    : [...prevState.expandedSchools, schoolName]
            )
        }));
    };

    toggleSubject = (key) => {
        this.setState(prevState => ({
            expandedSubjects: new Set(
                prevState.expandedSubjects.has(key)
                    ? Array.from(prevState.expandedSubjects).filter(k => k !== key)
                    : [...prevState.expandedSubjects, key]
            )
        }));
    };

    toggleGrade = (key) => {
        this.setState(prevState => ({
            expandedGrades: new Set(
                prevState.expandedGrades.has(key)
                    ? Array.from(prevState.expandedGrades).filter(k => k !== key)
                    : [...prevState.expandedGrades, key]
            )
        }));
    };

    expandAll = () => {
        const stats = this.calculateStats();
        const allSchools = new Set();
        const allSubjects = new Set();
        const allGrades = new Set();

        stats.forEach(school => {
            allSchools.add(school.name);
            Object.entries(school.subjects).forEach(([subject, subjectData]) => {
                const subjectKey = `${school.name}-${subject}`;
                allSubjects.add(subjectKey);

                Object.keys(subjectData.grades).forEach(grade => {
                    allGrades.add(`${subjectKey}-${grade}`);
                });
            });
        });

        this.setState({
            expandedSchools: allSchools,
            expandedSubjects: allSubjects,
            expandedGrades: allGrades
        });
    };

    collapseAll = () => {
        this.setState({
            expandedSchools: new Set(),
            expandedSubjects: new Set(),
            expandedGrades: new Set()
        });
    };

    renderCountCell = (count) => {
        return (
            <td style={{
                padding: '12px',
                textAlign: 'center',
                minWidth: '100px',
                fontFamily: 'monospace',
                fontSize: '0.85rem'
            }}>
                {count || '-'}
            </td>
        );
    };

    renderHierarchyRow = (item, level, key, hasChildren = false) => {
        const { teacherData } = this.props;
        const isExpanded = level === 'school' ? this.state.expandedSchools.has(item.name) :
            level === 'subject' ? this.state.expandedSubjects.has(key) :
                level === 'grade' ? this.state.expandedGrades.has(key) : false;

        const toggleExpand = level === 'school' ? () => this.toggleSchool(item.name) :
            level === 'subject' ? () => this.toggleSubject(key) :
                level === 'grade' ? () => this.toggleGrade(key) : null;

        const indent = level === 'subject' ? 20 : level === 'grade' ? 40 : level === 'teacher' ? 60 : 0;
        const displayName = level === 'teacher' ? teacherData[item.teacherId]?.fullName : item.name;

        return (
            <tr key={key} style={{
                backgroundColor: level === 'teacher' ? '#fafafa' : 'white'
            }}>
                <td style={{
                    padding: '12px',
                    width: '250px',
                    whiteSpace: 'nowrap'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: `${indent}px`
                    }}>
                        {hasChildren && (
                            <span
                                style={{
                                    cursor: 'pointer',
                                    marginRight: '8px',
                                    color: '#666'
                                }}
                                onClick={toggleExpand}
                            >
                                {isExpanded ? '▼' : '►'}
                            </span>
                        )}
                        <span style={{
                            fontWeight: level === 'school' ? '500' : '400',
                            color: level === 'teacher' ? '#666' : '#000'
                        }}>
                            {displayName}
                        </span>
                    </div>
                </td>
                {['Q1', 'Q2', 'Q3', 'Q4'].map(quarter =>
                    this.renderCountCell(item.counts[quarter])
                )}
            </tr>
        );
    };

    render() {
        const stats = this.calculateStats();
        const { selectedCategory } = this.state;

        return (
            <Card
                title="Assessments Given by Teachers"
                extra={
                    <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                        <div style={{
                            display: 'flex',
                            gap: '8px',
                            borderRight: '1px solid #e8e8e8',
                            paddingRight: '16px',
                        }}>
                            {[
                                { value: 'all', label: 'All' },
                                { value: 'common_assessment', label: 'Common Assessments' },
                                { value: 'benchmark', label: 'Benchmarks' }
                            ].map(option => (
                                <Button
                                    key={option.value}
                                    onClick={() => this.handleCategoryChange(option.value)}
                                    type={selectedCategory === option.value ? 'primary' : 'default'}
                                >
                                    {option.label}
                                </Button>
                            ))}
                        </div>
                        <div>
                            <Button onClick={this.expandAll} style={{ marginRight: '8px' }}>Expand All</Button>
                            <Button onClick={this.collapseAll}>Collapse All</Button>
                        </div>
                    </div>
                }
            >
                <div style={{ overflowX: 'auto' }}>
                    <table style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        fontSize: '14px'
                    }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #e8e8e8' }}>
                                <th style={{
                                    textAlign: 'left',
                                    padding: '12px',
                                    backgroundColor: '#fafafa',
                                    fontWeight: 500
                                }}>
                                    Name
                                </th>
                                {['Q1', 'Q2', 'Q3', 'Q4'].map(quarter => (
                                    <th key={quarter} style={{
                                        padding: '12px',
                                        backgroundColor: '#fafafa',
                                        fontWeight: 500,
                                        textAlign: 'center'
                                    }}>
                                        {quarter}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {stats.map(school => {
                                const rows = [];

                                rows.push(this.renderHierarchyRow(school, 'school', school.name, true));

                                if (this.state.expandedSchools.has(school.name)) {
                                    Object.entries(school.subjects).forEach(([subjectName, subject]) => {
                                        const subjectKey = `${school.name}-${subjectName}`;
                                        rows.push(this.renderHierarchyRow(subject, 'subject', subjectKey, true));

                                        if (this.state.expandedSubjects.has(subjectKey)) {
                                            Object.keys(subject.grades).sort(gradeNameSort).forEach(gradeName => {
                                                let grade = subject.grades[gradeName];
                                                const gradeKey = `${subjectKey}-${gradeName}`;
                                                rows.push(this.renderHierarchyRow(grade, 'grade', gradeKey, true));

                                                if (this.state.expandedGrades.has(gradeKey)) {
                                                    Object.keys(grade.teachers).sort((a, b) => {
                                                        const teacherA = this.props.teacherData[a];
                                                        const teacherB = this.props.teacherData[b];
                                                        return teacherA?.lastName.localeCompare(teacherB?.lastName);
                                                    }).forEach(teacherId => {
                                                        let teacher = grade.teachers[teacherId];
                                                        rows.push(this.renderHierarchyRow(
                                                            teacher,
                                                            'teacher',
                                                            `${gradeKey}-${teacherId}`,
                                                            false
                                                        ));
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }

                                return rows;
                            })}
                        </tbody>
                    </table>
                </div>
            </Card>
        );
    }
}

export default AssessmentCountView;
