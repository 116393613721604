import React, { Component } from 'react'
import { Route } from 'react-router-dom'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
//import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
//import { getIDFromURL } from '../.././Util'
import PacingGuideSC from './PacingGuideSC'
import PacingGuideLessonPlanV2 from './PacingGuideLessonPlanV2'
import PacingGuideLessonPlanV3 from './PacingGuideLessonPlanV3'
import WeeklyPlanner from './WeeklyPlanner'
import { db, firebase } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { flattenDoc, getSCBiology1Standards, getStandardsColType, getStateStandardNamesDict } from '../../Util'
import { Button } from 'antd'
//const { Content } = Layout

const PacingGuideComponent = (props, pacingGuideBaseProps, state) => {
    // other state pacing guides can be handled here with conditionals
    return <PacingGuideSC
        pacingGuideBaseProps={state}
        teacherBaseProps={pacingGuideBaseProps.teacherBaseProps}
        {...props}
    />
}

const LessonPlanComponent = (props, pacingGuideBaseProps, state) => {
    return <PacingGuideLessonPlanV3
        pacingGuideBaseProps={state}
        teacherBaseProps={pacingGuideBaseProps.teacherBaseProps}
        {...props}
    />
}

const WeeklyPlannerComponent = (props, pacingGuideBaseProps, state) => {
    return <WeeklyPlanner
        pacingGuideBaseProps={state}
        teacherBaseProps={pacingGuideBaseProps.teacherBaseProps}
        {...props}
    />
}

class PacingGuide extends Component {
    state = {
        prevStandardName: '',
        prevStandardCategory: '',
        standardName: 'Kindergarten',
        standardCategory: 'ELA',
        standardCategories: ['ELA', 'Math', 'Social Studies', 'Science'],
        standards: [],
        standardsDict: {},
        standardNamesDict: {},
        // standards that will be official in the future
        futureStandards: [],
        futureStandardsDict: {},
        setWeeksFunc: (weeks) => { },
        onStandardNameChangeFunc: (value) => { },
        onStandardCategoryChangeFunc: (value) => { },
        setStandardNameAndCategoryFunc: (standardName, standardCategory) => { },
        onSetActiveYearFunc: (year) => { },
        onSetPacingGuideMode: (mode) => { },
        totalWeeks: 37,
        weeks: null,
        weekNums: [],
        gradeDictAcrossCategories: {
            'Kindergarten': 'K',
            '1st Grade': '1',
            '2nd Grade': '2',
            '3rd Grade': '3',
            '4th Grade': '4',
            '5th Grade': '5',
            '6th Grade': '6',
            '7th Grade': '7',
            '8th Grade': '8',
        },
        pacingGuide: null,
        pacingGuideListener: null,
        futurePacingGuide: null,
        scheduledStandardsDict: {},
        mounted: false,
        pacingGuidePDFURL: '',
        pacingGuidePDFURLLoading: false,
        pacingGuideHasData: false,
        assessments: null,
        assessmentsDict: null,
        assessmentsWeekDict: {},
        biology1SCStandardsDict: getSCBiology1Standards(),
        quizzes: [],
        quizzesForEndWeek: {},
        state: '',
        isV3: true,
        standardYears: null,
        prevYear: '-1',
        activeYear: '',
        pacingGuideMode: 'current',
        initialized: false,
        userSettingsInitialized: false,
    }

    componentDidMount() {
        this.setState({
            setWeeksFunc: this.setWeeks,
            onStandardNameChangeFunc: this.onStandardNameChange,
            onStandardCategoryChangeFunc: this.onStandardCategoryChange,
            setStandardNameAndCategoryFunc: this.setStandardNameAndCategory,
            onSetActiveYearFunc: this.onSetActiveYear,
            onSetPacingGuideMode: this.onSetPacingGuideMode
        })

        let weekNums = []
        for (let i = 0; i < this.state.totalWeeks; i++) {
            weekNums.push(i)
        }
        this.setState({
            weekNums: weekNums,
        })

        this.getData()

        this.setState({
            mounted: true,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    componentWillUnmount() {
        if (this.state.pacingGuideListener) {
            this.state.pacingGuideListener()
        }
    }

    tryUpdateUserSettings = () => {
        if (this.props.teacherBaseProps &&
            this.props.teacherBaseProps.user &&
            this.props.teacherBaseProps.user.id) {
            db.collection(ColType.userSettings)
                .doc(this.props.teacherBaseProps.user.id)
                .set({
                    pacingGuideStandardCategory: this.state.standardCategory,
                    pacingGuideStandardName: this.state.standardName,
                    updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
                }, { merge: true });
        }
    }

    onSetPacingGuideMode = (mode) => {
        let stateObj = {
            pacingGuideMode: mode,
        }

        // current year standard names do not have this future standardName, go back to K
        if (mode === 'current' &&
            this.state.standardNamesDict &&
            this.state.standardNamesDict.hasOwnProperty(this.state.standardCategory) &&
            this.state.standardNamesDict[this.state.standardCategory] &&
            !this.state.standardNamesDict[this.state.standardCategory].includes(this.state.standardName)) {
            stateObj.standardName = 'Kindergarten';
        } else if (mode === 'future' &&
            this.state.standardNamesDict &&
            this.state.standardNamesDict.hasOwnProperty('Future' + this.state.standardCategory) &&
            this.state.standardNamesDict['Future' + this.state.standardCategory] &&
            !this.state.standardNamesDict['Future' + this.state.standardCategory].includes(this.state.standardName)) {
            stateObj.standardName = 'Kindergarten';
        }

        this.setState(stateObj, () => {
            // a change in standardName occurred, get data
            if (stateObj.hasOwnProperty('standardName')) {
                this.getData()
            }
        });
    }

    onSetActiveYear = (year) => {
        this.setState({
            activeYear: year,
        }, () => {
            this.getData()
        })
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, () => resolve(this.state));
        });
    }

    getData = async () => {
        if (!(this.props.teacherBaseProps &&
            this.props.teacherBaseProps.user &&
            this.props.teacherBaseProps.district)) {
            return
        }

        if (!this.state.userSettingsInitialized) {
            try {
                const userSettings = await db.collection(ColType.userSettings)
                    .doc(this.props.teacherBaseProps.user.id)
                    .get()
                if (userSettings.exists) {
                    const userSettingsData = flattenDoc(userSettings)
                    if (userSettingsData.hasOwnProperty('pacingGuideStandardCategory') &&
                        userSettingsData.hasOwnProperty('pacingGuideStandardName') &&
                        userSettingsData.pacingGuideStandardCategory &&
                        userSettingsData.pacingGuideStandardName) {
                        await this.setStateAsync({
                            standardCategory: userSettingsData.pacingGuideStandardCategory,
                            standardName: userSettingsData.pacingGuideStandardName,
                        });
                    }
                } else {

                }
            } catch (e) {
                //console.error(e)
            }
            this.setState({
                userSettingsInitialized: true,
            });
        }

        this.setState({
            state: this.props.teacherBaseProps.district.state,
        })

        if (Object.keys(this.state.standardNamesDict).length === 0) {
            this.setState({
                standardNamesDict: getStateStandardNamesDict(this.props.teacherBaseProps.district.state)
            })
        }

        if (this.state.standardName === this.state.prevStandardName &&
            this.state.standardCategory === this.state.prevStandardCategory &&
            this.state.activeYear === this.state.prevYear) {
            return
        }

        this.setState({
            prevStandardName: this.state.standardName,
            prevStandardCategory: this.state.standardCategory,
            prevYear: this.state.activeYear,
        })

        const standardsColType = getStandardsColType(this.props.teacherBaseProps.district.state)
        //console.log(this.props.teacherBaseProps.district.state, standardsColType)

        if (this.state.pacingGuideListener) {
            this.state.pacingGuideListener()
            this.setState({
                pacingGuideListener: null,
            })
        }

        this.setState({
            pacingGuidePDFURLLoading: true,
        }, () => {
            var storage = firebase.app().storage("gs://pacingguidepdfs")
            var child = storage.ref().child(this.props.teacherBaseProps.district.id)
            // get year folders
            child.listAll().then((res) => {
                //console.log('storage res', res)
                // this is the year folder, maybe need to sort by year later
                // always using idx 0 for now
                if (res.prefixes.length > 0) {
                    let yearFolder = res.prefixes[0]
                    let fn = this.state.standardCategory + '_' + this.state.standardName + '.pdf'
                    fn = fn.replaceAll(' ', '_')
                    yearFolder.child(fn).getDownloadURL().then((url) => {
                        //console.log('url', url)
                        this.setState({
                            pacingGuidePDFURL: url,
                            pacingGuidePDFURLLoading: false,
                        })
                    }).catch((error) => {
                        //console.log(error)
                        this.setState({
                            pacingGuidePDFURL: '',
                            pacingGuidePDFURLLoading: false,
                        })
                    })
                }
            }).catch((error) => {
                //console.log(error)
            })
        })

        let standardsSnapshot = null

        let state = this.props.teacherBaseProps.district.state
        // test district is NY, but we want SC standards
        if (state === 'NY') {
            state = 'SC'
        }
        let standardYears = this.state.standardYears
        let activeYear = this.state.activeYear
        if (!this.state.standardYears) {
            let doc = await db.collection(ColType.standardYears)
                .doc('all')
                .get()

            if (!doc.exists) {
                //console.log('standardYears doc does not exist')
                return
            }
            standardYears = flattenDoc(doc)
            activeYear = standardYears.currentYear
            this.setState({
                standardYears: standardYears,
                activeYear: standardYears.currentYear,
            })
        }

        const stateAndStandardCategory = state + this.state.standardCategory
        if (!standardYears.hasOwnProperty(stateAndStandardCategory)) {
            console.error('standardYears does not have key', stateAndStandardCategory)
            return
        }
        let standardYear = standardYears[stateAndStandardCategory]
        let standardName = this.state.standardName
        // KY ELA standards are 9-10 and 11-12
        if (state === 'KY' && this.state.standardCategory === 'ELA') {
            if (standardName === '10th Grade') {
                standardName = '9th Grade'
            } else if (standardName === '12th Grade') {
                standardName = '11th Grade'
            }
        }

        if (this.state.standardCategory === 'Math' &&
            state === 'SC') {
            db.collection(ColType.standards)
                .where('state', '==', state)
                .where('standardName', '==', standardName)
                .where('standardCategory', '==', this.state.standardCategory)
                .where('year', '==', '2023')
                .orderBy('sortOrder')
                .get()
                .then((querySnapshot) => {
                    let futureStandards = []
                    let futureStandardsDict = {}
                    querySnapshot.forEach((doc) => {
                        let standard = flattenDoc(doc)
                        futureStandards.push(standard)
                        futureStandardsDict[standard.standard] = standard
                    })
                    //console.log('futureStandards', futureStandards)
                    this.setState({
                        futureStandards: futureStandards,
                        futureStandardsDict: futureStandardsDict,
                    }, () => {
                        db.collection(ColType.futurePacingGuides)
                            .where('standardName', '==', this.state.standardName)
                            .where('standardCategory', '==', this.state.standardCategory)
                            .get()
                            .then((querySnapshot) => {
                                let futurePacingGuide = null
                                querySnapshot.forEach((doc) => {
                                    futurePacingGuide = flattenDoc(doc)
                                })
                                //console.log('futurePacingGuide', futurePacingGuide)
                                if (futurePacingGuide) {
                                    // add standards to week 0
                                    let weeks = futurePacingGuide.weeks
                                    weeks[0] = []
                                    for (let standard of futureStandards) {
                                        weeks[0].push(standard)
                                    }
                                }
                                let pacingGuideMode = this.state.pacingGuideMode
                                if (!futurePacingGuide && pacingGuideMode === 'future') {
                                    pacingGuideMode = 'current'
                                }
                                this.setState({
                                    futurePacingGuide: futurePacingGuide,
                                    pacingGuideMode: pacingGuideMode,
                                })
                            })
                    })
                })
        } else {
            // clear future standards
            this.setState({
                pacingGuideMode: 'current',
                futurePacingGuide: null,
                futureStandards: [],
                futureStandardsDict: {},
            })
        }

        standardsSnapshot = await db.collection(ColType.standards)
            .where('state', '==', state)
            .where('standardName', '==', standardName)
            .where('standardCategory', '==', this.state.standardCategory)
            .where('year', '==', standardYear)
            .orderBy('sortOrder')
            .get()

        let standards = []
        let standardsDict = {}
        standardsSnapshot.forEach((doc) => {
            let standard = flattenDoc(doc)
            standards.push(standard)
            standardsDict[standard.standard] = standard
        })

        //console.log(standards)

        let pacingGuideListener = db.collection(ColType.pacingGuides)
            .where('standardName', '==', this.state.standardName)
            .where('standardCategory', '==', this.state.standardCategory)
            .where('districtId', '==', this.props.teacherBaseProps.district.id)
            .where('year', '==', activeYear)
            .limit(20)
            .onSnapshot((querySnapshot) => {
                if (querySnapshot.empty) {
                    // pacing guides are created ahead of time
                    console.error('pacing guide does not exist')
                    // set pacing guide to empty
                    this.setState({
                        initialized: true,
                        pacingGuide: null,
                    })
                    return
                }
                if (querySnapshot.docs.length > 1) {
                    console.error('more than one pacing guide exists')
                }

                let pacingGuide = flattenDoc(querySnapshot.docs[0])

                // always set week[0] to standards as they may have changed
                let weeks = pacingGuide.weeks
                weeks[0] = []
                for (let standard of standards) {
                    weeks[0].push(standard)
                }

                // only used to determine which select we should show in LessonPlanV2
                let pacingGuideHasData = false
                let scheduledStandardsDict = {}
                // skip week 0 (side menu)
                for (let i = 1; i < this.state.totalWeeks; i++) {
                    let scheduledStandards = weeks[i]
                    if (scheduledStandards.length > 0) {
                        pacingGuideHasData = true
                    }
                    for (let standard of scheduledStandards) {
                        let key = standard.standard
                        // if the scheduled standard is not in the side menu standards,
                        // continue (this is to handle Biology standards in Earth Science)
                        // Biology standards won't be in the side menu standards for Earth Science
                        if (!standardsDict.hasOwnProperty(key)) {
                            continue
                        }
                        if (!scheduledStandardsDict.hasOwnProperty(key)) {
                            scheduledStandardsDict[key] = 0
                        }
                        scheduledStandardsDict[key] += 1
                    }
                }

                //console.log(pacingGuide)

                this.setState({
                    initialized: true,
                    pacingGuide: pacingGuide,
                    pacingGuideHasData: true,
                    scheduledStandardsDict: scheduledStandardsDict,
                })
            })

        this.setState({
            pacingGuideListener: pacingGuideListener,
            standards: standards,
            standardsDict: standardsDict,
        })

        db.collection(ColType.quizzes)
            .where('standardName', '==', this.state.standardName)
            .where('standardCategory', '==', this.state.standardCategory)
            .where('districtId', '==', this.props.teacherBaseProps.district.id)
            .where('queryId', '==', this.props.teacherBaseProps.district.id)
            .where('year', '==', activeYear)
            .where('live', '==', true)
            .orderBy('createdAt', 'desc')
            .get()
            .then((querySnapshot) => {
                let quizzes = []
                let quizzesForEndWeek = {}
                querySnapshot.forEach((doc) => {
                    let quiz = flattenDoc(doc)
                    //console.log('quiz', quiz)
                    quiz.docs = JSON.parse(quiz.docs)
                    // shuffle questions
                    quiz.docs.map((doc) => {
                        try {
                            // will throw error here before shuffle, determining if format is correct
                            let answers = doc.components[0].answers
                            // format is known, shuffle
                            doc.components[0].answers = shuffleArray(doc.components[0].answers)
                        } catch (e) {
                            // pass
                            return false
                        }
                    })

                    quizzes.push(quiz)
                    if (!quizzesForEndWeek.hasOwnProperty(quiz.endWeek)) {
                        quizzesForEndWeek[quiz.endWeek] = []
                    }
                    quizzesForEndWeek[quiz.endWeek].push(quiz)
                })

                Object.keys(quizzesForEndWeek).map((key) => {
                    quizzesForEndWeek[key].sort((a, b) => {
                        if (a.hasOwnProperty('assessmentTitle') &&
                            b.hasOwnProperty('assessmentTitle')) {
                            if (a.assessmentTitle < b.assessmentTitle) {
                                return -1
                            } else if (a.assessmentTitle > b.assessmentTitle) {
                                return 1
                            }

                            return 0
                        }

                        return 0
                    })

                    return false
                })

                this.setState({
                    quizzes: quizzes,
                    quizzesForEndWeek: quizzesForEndWeek,
                })
                //console.log('quizzes', quizzes, quizzesForEndWeek)
            })
    }

    getBiologyStandards = () => {
        const standardsColType = getStandardsColType(this.props.teacherBaseProps.district.state)

        db.collection(standardsColType)
            .where('standardName', '==', 'Biology')
            .where('standardCategory', '==', 'Science')
            .orderBy('sortOrder')
            .get()
            .then((querySnapshot) => {
                let standards = []
                let standardsDict = {}
                querySnapshot.forEach((doc) => {
                    let standard = flattenDoc(doc)
                    standards.push(standard)
                    standardsDict[standard.standard] = standard
                })

                let pacingGuide = this.state.pacingGuide
                for (let standard of standards) {
                    pacingGuide.weeks[0].push(standard)
                }
                this.setState({
                    pacingGuide: pacingGuide,
                })
            })
    }

    filterOutBiology2Standards = () => {
        if (this.state.standardName === 'Biology') {
            let pacingGuide = this.state.pacingGuide
            for (let i = 1; i < this.state.totalWeeks; i++) {
                let standards = pacingGuide.weeks[i]
                let filteredStandards = []
                for (let standard of standards) {
                    if (this.state.biology1SCStandardsDict.hasOwnProperty(standard.standard)) {
                        filteredStandards.push(standard)
                    }
                }
                pacingGuide.weeks[i] = filteredStandards
            }
            this.setState({
                pacingGuide: pacingGuide,
            })
        }
    }

    setStandards = (standards) => {
        this.setStandards({
            standards: standards,
        })
    }

    onStandardNameChange = (value) => {
        this.setState({
            standardName: value,
        }, () => {
            this.getData()
            this.tryUpdateUserSettings()
        })
    }

    onStandardCategoryChange = (value) => {
        let stateObj = { standardCategory: value }
        if (!this.state.gradeDictAcrossCategories.hasOwnProperty(this.state.standardName)) {
            stateObj.standardName = 'Kindergarten'
        }
        this.setState(stateObj, () => {
            this.getData()
            this.tryUpdateUserSettings()
        })
    }

    setStandardNameAndCategory = (standardName, standardCategory) => {
        let stateObj = {
            standardName: standardName,
            standardCategory: standardCategory,
        }
        if (!this.state.gradeDictAcrossCategories.hasOwnProperty(standardName)) {
            stateObj.standardName = 'Kindergarten'
        }
        this.setState(stateObj, () => {
            this.getData()
        })
    }

    setWeeks = (weeks) => {
        if (this.state.pacingGuide) {
            // set immediately so that there is no visual delay
            // when the db update below finishes, the listener
            // will also update
            let pacingGuide = this.state.pacingGuide
            pacingGuide.weeks = weeks
            this.setState({
                pacingGuide: pacingGuide,
            })

            // Make a shallow copy of the object
            let weeksToDB = Object.assign({}, weeks);
            // Delete the first property (standards list that is
            // added in the onSnapshot listener on each update)
            // save space by not adding to db
            delete weeksToDB[0];

            db.collection(ColType.pacingGuides)
                .doc(this.state.pacingGuide.id)
                .update({
                    weeks: weeksToDB,
                })
        }
    }

    isStandardScheduled = (weeks, standard) => {
        for (let i = 1; i < this.state.totalWeeks; i++) {
            const standards = weeks[i]
            for (let j = 0; j < standards.length; j++) {
                const standardInternal = standards[j]
                if (standardInternal.standard === standard.standard) {
                    return true
                }
            }
        }
        return false
    }

    updatePacingGuideStandards = () => {
        // updates the pacing guide standards with the latest standards
        // based on the standard.standard
        if (!this.state.pacingGuide) {
            return
        }
        if (!this.state.standardsDict) {
            return
        }
        let weeks = {}
        for (let i = 0; i < this.state.totalWeeks; i++) {
            weeks[i] = []
        }
        for (let i = 1; i < this.state.totalWeeks; i++) {
            let standards = this.state.pacingGuide.weeks[i]
            for (let standard of standards) {
                if (!this.state.standardsDict.hasOwnProperty(standard.standard)) {
                    console.error('standard not found', standard.standard, 'not adding');
                    //return;
                    continue;
                }

                // add newer version of the standard
                weeks[i].push(this.state.standardsDict[standard.standard])
            }
        }

        console.log('updated');
        this.setWeeks(weeks);
    }

    setPacingGuideWithPresetStandards = () => {
        /*
        // SC Physics default pacing guide
        let pacingGuideStandardsDict = {
            // Unit 1: Forces & Motion
            1: ["P-PS2-1"],
            2: ["P-PS2-1", "P-PS2-2"],
            3: ["P-PS2-2"],
            4: ["P-PS2-3"],

            // Unit 2: Conservation Laws
            5: ["P-PS2-2"],
            6: ["P-PS2-3"],
            7: ["P-PS3-1"],
            8: ["P-PS3-1", "P-PS2-2"],

            // Unit 3: Energy Transformations
            9: ["P-PS3-1"],
            10: ["P-PS3-2"],
            11: ["P-PS3-2", "P-PS3-3"],
            12: ["P-PS3-3"],

            // Unit 4: Gravitational & Electric Forces
            13: ["P-PS2-4"],
            14: ["P-PS2-4", "P-PS3-5"],
            15: ["P-PS3-5"],
            16: ["P-PS2-6"],

            // Unit 5: Electromagnetism
            17: ["P-PS2-5"],
            18: ["P-PS2-5", "P-PS3-5"],
            19: ["P-PS3-5"],
            20: ["P-PS2-6"],

            // Unit 6: Waves & Wave Properties
            21: ["P-PS4-1"],
            22: ["P-PS4-1", "P-PS4-2"],
            23: ["P-PS4-2"],
            24: ["P-PS4-3"],

            // Unit 7: Electromagnetic Waves
            25: ["P-PS4-3"],
            26: ["P-PS4-4"],
            27: ["P-PS4-4", "P-PS4-5"],
            28: ["P-PS4-5"],

            // Unit 8: Modern Physics Applications
            29: ["P-PS4-3"],
            30: ["P-PS4-4"],
            31: ["P-PS4-5"],
            32: ["P-PS4-3", "P-PS4-4"],

            // Unit 9: Technology & Engineering Applications
            33: ["P-PS2-3", "P-PS3-3"],
            34: ["P-PS3-3", "P-PS4-2"],
            35: ["P-PS4-2", "P-PS4-5"],
            36: ["P-PS4-5"]
        };
        */
        /*
         // CommonLit KY ELA 6th grade default pacing guide
         let pacingGuideStandardsDict = {
             // Unit 1: Characters Who Change and Grow (Weeks 1-6)
             1: ["RL.6.1", "RL.6.3", "C.6.2.a", "L.6.4.d"],
             2: ["RL.6.2", "RL.6.3", "C.6.2.b", "L.6.2.a"],
             3: ["RL.6.3", "RL.6.4", "C.6.2.c", "L.6.2.b"],
             4: ["RL.6.5", "RL.6.6", "C.6.2.d", "L.6.3.a"],
             5: ["RL.6.7", "RL.6.9", "C.6.2.e", "L.6.3.b"],
             6: ["RL.6.10", "C.6.2.f", "C.6.2.g", "L.6.4.a"],
 
             // Unit 2: The Recipe for Success (Weeks 7-12)
             7: ["RI.6.1", "RI.6.2", "C.6.2.h", "L.6.4.b"],
             8: ["RI.6.3", "RI.6.4", "C.6.3.a", "L.6.4.c"],
             9: ["RI.6.5", "RI.6.6", "C.6.3.b", "L.6.4.d"],
             10: ["RI.6.7", "RI.6.8", "C.6.3.c", "L.6.5.a"],
             11: ["RI.6.9", "RI.6.10", "C.6.3.d", "L.6.5.b"],
             12: ["C.6.3.e", "C.6.3.f", "C.6.3.g", "L.6.5.c"],
 
             // Unit 3: The Giver (Weeks 13-18)
             13: ["C.6.1.a", "C.6.1.b", "C.6.4", "L.6.1.a"],
             14: ["C.6.1.c", "C.6.1.d", "C.6.5", "L.6.1.b"],
             15: ["C.6.1.e", "C.6.1.f", "C.6.6", "L.6.1.c"],
             16: ["C.6.1.g", "C.6.7", "RL.6.1", "RL.6.2"],
             17: ["RL.6.3", "RL.6.4", "RL.6.5", "L.6.2.a"],
             18: ["RL.6.6", "RL.6.7", "RL.6.9", "L.6.2.b"],
 
             // Unit 4: Our Changing Oceans (Weeks 19-24)
             19: ["RL.6.10", "RI.6.1", "RI.6.2", "L.6.3.a"],
             20: ["RI.6.3", "RI.6.4", "RI.6.5", "L.6.3.b"],
             21: ["RI.6.6", "RI.6.7", "RI.6.8", "L.6.4.a"],
             22: ["RI.6.9", "RI.6.10", "C.6.1.a", "L.6.4.b"],
             23: ["C.6.1.b", "C.6.1.c", "C.6.1.d", "L.6.4.c"],
             24: ["C.6.1.e", "C.6.1.f", "C.6.1.g", "L.6.4.d"],
 
             // Unit 5: The Forces That Shape Us (Weeks 25-30)
             25: ["C.6.2.a", "C.6.2.b", "C.6.2.c", "L.6.5.a"],
             26: ["C.6.2.d", "C.6.2.e", "C.6.2.f", "L.6.5.b"],
             27: ["C.6.2.g", "C.6.2.h", "C.6.3.a", "L.6.5.c"],
             28: ["C.6.3.b", "C.6.3.c", "C.6.3.d", "L.6.1.a"],
             29: ["C.6.3.e", "C.6.3.f", "C.6.3.g", "L.6.1.b"],
             30: ["C.6.4", "C.6.5", "C.6.6", "L.6.1.c"],
 
             // Unit 6: The Power of Play (Weeks 31-36)
             31: ["C.6.7", "RL.6.1", "RL.6.2", "L.6.2.a"],
             32: ["RL.6.3", "RL.6.4", "RL.6.5", "L.6.2.b"],
             33: ["RL.6.6", "RL.6.7", "RL.6.9", "L.6.3.a"],
             34: ["RL.6.10", "RI.6.1", "RI.6.2", "L.6.3.b"],
             35: ["RI.6.3", "RI.6.4", "RI.6.5", "L.6.4.a"],
             36: ["RI.6.6", "RI.6.7", "RI.6.8", "L.6.4.b"]
         };*/
        /*
       // CommonLit KY ELA 7th grade default pacing guide
       let pacingGuideStandardsDict = {
           // Unit 1: Community and Belonging (Weeks 1-6) 
           1: ["RI.7.1", "RI.7.2", "C.7.2.a", "L.7.4.d"],
           2: ["RL.7.2", "RL.7.3", "C.7.2.b", "L.7.1.a"],
           3: ["RL.7.3", "RL.7.4", "C.7.2.c", "L.7.1.b"],
           4: ["RL.7.2", "RL.7.3", "C.7.2.d", "L.7.2.a"],
           5: ["RL.7.3", "RL.7.6", "C.7.2.e", "L.7.2.b"],
           6: ["RL.7.2", "RL.7.3", "C.7.2.f", "L.7.3.a"],

           // Unit 2: The Recipe for Success (Weeks 7-12)
           7: ["RI.7.2", "RI.7.3", "C.7.1.a", "L.7.4.a"],
           8: ["RI.7.4", "RI.7.5", "C.7.1.b", "L.7.4.b"],
           9: ["RI.7.6", "RI.7.7", "C.7.1.c", "L.7.4.c"],
           10: ["RI.7.8", "RI.7.9", "C.7.1.d", "L.7.4.d"],
           11: ["RI.7.10", "C.7.1.e", "C.7.1.f", "L.7.5.a"],
           12: ["RI.7.2", "RI.7.3", "C.7.1.g", "L.7.5.b"],

           // Unit 3: Narrative Unit (Weeks 13-18)
           13: ["RL.7.1", "RL.7.2", "C.7.3.a", "L.7.5.c"],
           14: ["RL.7.3", "RL.7.4", "C.7.3.b", "L.7.1.a"],
           15: ["RL.7.5", "RL.7.6", "C.7.3.c", "L.7.1.b"],
           16: ["RL.7.7", "RL.7.9", "C.7.3.d", "L.7.2.a"],
           17: ["RL.7.10", "C.7.3.e", "C.7.3.f", "L.7.2.b"],
           18: ["RL.7.2", "RL.7.3", "C.7.3.g", "L.7.3.a"],

           // Unit 4: Research Unit (Weeks 19-24)
           19: ["RI.7.1", "RI.7.2", "C.7.4", "L.7.4.a"],
           20: ["RI.7.3", "RI.7.4", "C.7.5", "L.7.4.b"],
           21: ["RI.7.5", "RI.7.6", "C.7.6", "L.7.4.c"],
           22: ["RI.7.7", "RI.7.8", "C.7.7", "L.7.4.d"],
           23: ["RI.7.9", "RI.7.10", "C.7.2.g", "L.7.5.a"],
           24: ["RI.7.2", "RI.7.3", "C.7.2.h", "L.7.5.b"],

           // Unit 5: Poetry and Literary Analysis (Weeks 25-30)
           25: ["RL.7.1", "RL.7.2", "C.7.1.a", "L.7.5.c"],
           26: ["RL.7.3", "RL.7.4", "C.7.1.b", "L.7.1.a"],
           27: ["RL.7.5", "RL.7.6", "C.7.1.c", "L.7.1.b"],
           28: ["RL.7.7", "RL.7.9", "C.7.1.d", "L.7.2.a"],
           29: ["RL.7.10", "C.7.1.e", "C.7.1.f", "L.7.2.b"],
           30: ["RL.7.2", "RL.7.3", "C.7.1.g", "L.7.3.a"],

           // Unit 6: Synthesis and Application (Weeks 31-36)
           31: ["RI.7.1", "RI.7.2", "C.7.2.a", "L.7.4.a"],
           32: ["RI.7.3", "RI.7.4", "C.7.2.b", "L.7.4.b"],
           33: ["RI.7.5", "RI.7.6", "C.7.2.c", "L.7.4.c"],
           34: ["RI.7.7", "RI.7.8", "C.7.2.d", "L.7.4.d"],
           35: ["RI.7.9", "RI.7.10", "C.7.2.e", "L.7.5.a"],
           36: ["RI.7.2", "RI.7.3", "C.7.2.f", "L.7.5.b"]
       };
       */
        /*
          // CommonLit KY ELA 8th grade default pacing guide
          let pacingGuideStandardsDict = {
              // Unit 1: Art of Suspense (Weeks 1-6)
              1: ["RL.8.1", "RL.8.3", "C.8.2.a", "L.8.1.a"],
              2: ["RL.8.3", "RL.8.4", "C.8.2.b", "L.8.1.b"],
              3: ["RL.8.5", "RL.8.6", "C.8.2.c", "L.8.1.c"],
              4: ["RL.8.2", "RL.8.3", "C.8.2.d", "L.8.2.a"],
              5: ["RL.8.4", "RL.8.6", "C.8.2.e", "L.8.2.b"],
              6: ["RL.8.7", "RL.8.9", "C.8.2.f", "L.8.2.c"],
  
              // Unit 2: Conveying Courage (Weeks 7-12)
              7: ["RI.8.1", "RI.8.2", "C.8.1.a", "L.8.3.a"],
              8: ["RI.8.3", "RI.8.4", "C.8.1.b", "L.8.4.a"],
              9: ["RI.8.5", "RI.8.6", "C.8.1.c", "L.8.4.b"],
              10: ["RI.8.7", "RI.8.8", "C.8.1.d", "L.8.4.c"],
              11: ["RI.8.9", "RI.8.10", "C.8.1.e", "L.8.4.d"],  // Added L.8.4.d here
              12: ["RL.8.2", "RL.8.3", "C.8.1.f", "L.8.5.a"],
  
              // Unit 3: Twelve Angry Men (Weeks 13-18) 
              13: ["RL.8.1", "RL.8.2", "C.8.3.a", "L.8.5.b"],  // Changed to L.8.5.b
              14: ["RL.8.3", "RL.8.4", "C.8.3.b", "L.8.1.a"],
              15: ["RL.8.5", "RL.8.6", "C.8.3.c", "L.8.1.b"],
              16: ["RL.8.7", "RL.8.9", "C.8.3.d", "L.8.1.c"],
              17: ["RL.8.10", "C.8.3.e", "C.8.3.f", "L.8.2.a"],
              18: ["RL.8.2", "RL.8.3", "C.8.3.g", "L.8.2.b"],
  
              // Unit 4: Contact Sports (Weeks 19-24)
              19: ["RI.8.1", "RI.8.2", "C.8.4", "L.8.2.c"],
              20: ["RI.8.3", "RI.8.4", "C.8.5", "L.8.3.a"],
              21: ["RI.8.5", "RI.8.6", "C.8.6", "L.8.4.a"],
              22: ["RI.8.7", "RI.8.8", "C.8.7", "L.8.4.b"],
              23: ["RI.8.9", "RI.8.10", "C.8.1.g", "L.8.4.c"],
              24: ["RI.8.2", "RI.8.3", "C.8.2.g", "L.8.4.d"],
  
              // Unit 5: Not That Different (Weeks 25-30)
              25: ["RL.8.1", "RL.8.2", "C.8.2.h", "L.8.5.a"],
              26: ["RL.8.3", "RL.8.4", "C.8.1.a", "L.8.5.b"],
              27: ["RL.8.5", "RL.8.6", "C.8.1.b", "L.8.1.a"],
              28: ["RL.8.7", "RL.8.9", "C.8.1.c", "L.8.1.b"],
              29: ["RL.8.10", "C.8.1.d", "C.8.1.e", "L.8.1.c"],
              30: ["RL.8.2", "RL.8.3", "C.8.1.f", "L.8.2.a"],
  
              // Unit 6: School Start Time Debate (Weeks 31-36)
              31: ["RI.8.1", "RI.8.2", "C.8.2.a", "L.8.2.b"],
              32: ["RI.8.3", "RI.8.4", "C.8.2.b", "L.8.2.c"],
              33: ["RI.8.5", "RI.8.6", "C.8.2.c", "L.8.3.a"],
              34: ["RI.8.7", "RI.8.8", "C.8.2.d", "L.8.5.a"],
              35: ["RI.8.9", "RI.8.10", "C.8.2.e", "L.8.5.b"],
              36: ["RI.8.2", "RI.8.3", "C.8.2.f", "L.8.5.c"]
          };
          */
        /*
       // KY 6th grade science default pacing guide
       let pacingGuideStandardsDict = {
           // Unit 1: Weather, Climate & Water Cycle (Weeks 1-6)
           1: ["6-ESS2-4", "6-ESS2-5"],
           2: ["6-ESS2-4", "6-ESS2-6", "6-PS1-4"],
           3: ["6-ESS2-5", "6-ESS2-6"],
           4: ["6-ESS2-4", "6-PS1-4"],
           5: ["6-ESS2-5", "6-ESS2-6"],
           6: ["6-ESS2-4", "6-ESS2-5", "6-ESS2-6"],

           // Unit 2: Plate Tectonics & Rock Cycling (Weeks 7-12)  
           7: ["6-ESS2-1", "6-ESS2-2"],
           8: ["6-ESS2-2", "6-ESS2-3", "6-PS1-1"],
           9: ["6-ESS2-1", "6-ESS2-3"],
           10: ["6-ESS2-1", "6-ESS2-2"],
           11: ["6-ESS2-2", "6-ESS2-3"],
           12: ["6-ESS2-1", "6-ESS2-2", "6-ESS2-3"],

           // Unit 3: Matter Cycling & Photosynthesis (Weeks 13-18)
           13: ["6-LS1-6", "6-LS2-3"],
           14: ["6-LS1-6", "6-LS2-3"],
           15: ["6-LS1-6", "6-LS2-3"],
           16: ["6-LS1-6", "6-LS2-3"],
           17: ["6-LS1-6"],
           18: ["6-LS2-3"],

           // Unit 4: Ecosystem Dynamics & Biodiversity (Weeks 19-24)
           19: ["6-LS2-1", "6-LS2-2", "MS-ETS1-1"],
           20: ["6-LS2-1", "6-LS2-2", "MS-ETS1-1"],
           21: ["6-LS2-1", "6-LS2-2", "MS-ETS1-1"],
           22: ["6-LS2-1", "6-LS2-3", "MS-ETS1-1"],
           23: ["6-LS2-2", "6-LS2-3"],
           24: ["6-LS2-1", "6-LS2-2", "6-LS2-3"],

           // Unit 5: Earth in Space (Weeks 25-30)
           25: ["6-ESS1-1", "6-ESS1-2"],
           26: ["6-ESS1-2", "6-ESS1-3"],
           27: ["6-ESS1-1", "6-ESS1-3"],
           28: ["6-ESS1-1", "6-ESS1-2"],
           29: ["6-ESS1-2", "6-ESS1-3", "6-PS2-4"],
           30: ["6-ESS1-1", "6-ESS1-2", "6-ESS1-3"],

           // Unit 6: Contact Forces (Weeks 31-36)
           31: ["6-PS2-1", "6-PS2-4", "MS-ETS1-2"],
           32: ["6-PS2-1", "6-PS2-4", "MS-ETS1-2"],
           33: ["6-PS2-1", "6-PS2-4", "MS-ETS1-3"],
           34: ["6-PS2-1", "6-PS1-1", "MS-ETS1-3"],
           35: ["6-PS2-4", "6-PS1-4", "6-PS1-1", "MS-ETS1-4"],
           36: ["6-PS2-1", "6-PS2-4", "6-PS1-4", "MS-ETS1-4"]
       };*/
        /*
         // KY 7th grade science default pacing guide
         let pacingGuideStandardsDict = {
             // Unit 1: Chemical Reactions & Matter (Weeks 1-5)
             1: ["7-PS1-2", "7-PS1-5"],
             2: ["7-PS1-2", "7-PS1-5"],
             3: ["7-PS1-2", "7-PS1-5"],
             4: ["7-PS1-5", "7-PS1-6"],
             5: ["7-PS1-2", "7-PS1-5", "7-PS1-6"],
 
             // Unit 2: Metabolic Reactions (Weeks 6-10)
             6: ["7-LS1-3", "7-LS1-7"],
             7: ["7-LS1-3", "7-PS1-2"],
             8: ["7-LS1-7", "7-PS1-2"],
             9: ["7-LS1-3", "7-LS1-7"],
             10: ["7-LS1-3", "7-LS1-7", "7-PS1-2"],
 
             // Unit 3: Cells & Systems (Weeks 11-15)
             11: ["7-LS1-1", "7-LS1-2"],
             12: ["7-LS1-2", "7-LS1-3"],
             13: ["7-LS1-1", "7-LS1-3"],
             14: ["7-LS1-2", "7-LS1-8"],
             15: ["7-LS1-1", "7-LS1-2", "7-LS1-3"],
 
             // Unit 4: Light and Matter (Weeks 16-20)
             16: ["7-PS4-2", "7-LS1-8"],
             17: ["7-PS4-2", "7-LS1-8"],
             18: ["7-PS4-2", "7-PS4-3"],
             19: ["7-PS4-2", "7-PS4-3"],
             20: ["7-PS4-2", "7-LS1-8", "7-PS4-3"],
 
             // Unit 5: Thermal Energy (Weeks 21-25)
             21: ["7-PS3-3", "7-PS3-4", "MS-ETS1-1"],
             22: ["7-PS3-4", "7-PS3-5", "MS-ETS1-2"],
             23: ["7-PS3-3", "7-PS4-2", "MS-ETS1-3"],
             24: ["7-PS3-4", "7-PS3-5", "MS-ETS1-4"],
             25: ["7-PS3-3", "7-PS3-4", "7-PS3-5"],
 
             // Unit 6: Sound Waves (Weeks 26-30)
             26: ["7-PS4-1", "7-PS4-2"],
             27: ["7-PS4-1", "7-LS1-8"],
             28: ["7-PS4-1", "7-PS4-2"],
             29: ["7-PS4-2", "7-LS1-8"],
             30: ["7-PS4-1", "7-PS4-2", "7-LS1-8"],
 
             // Unit 7: Forces at a Distance (Weeks 31-36)
             31: ["7-PS2-2", "7-PS2-3"],
             32: ["7-PS2-3", "7-PS3-1"],
             33: ["7-PS2-5", "7-PS3-2"],
             34: ["7-PS2-2", "7-PS3-1"],
             35: ["7-PS2-3", "7-PS2-5", "7-PS3-2"],
             36: ["7-PS2-2", "7-PS3-1", "7-PS3-2"]
         };
         */
        /*
        // KY 8th grade science default pacing guide
        let pacingGuideStandardsDict = {
            // Unit 1: Chemical Reactions & Energy (Weeks 1-7)
            1: ["8-PS1-3", "MS-ETS1-1"],
            2: ["8-PS1-3", "MS-ETS1-1", "MS-ETS1-2"],
            3: ["8-PS1-3", "MS-ETS1-2"],
            4: ["8-PS1-3", "MS-ETS1-3"],
            5: ["8-PS1-3", "MS-ETS1-3"],
            6: ["8-PS1-3", "MS-ETS1-4"],
            7: ["8-PS1-3", "MS-ETS1-4"],

            // Unit 2: Natural Hazards (Weeks 8-14)
            8: ["8-ESS3-2"],
            9: ["8-ESS3-2"],
            10: ["8-ESS3-2"],
            11: ["MS-ETS1-1", "MS-ETS1-2"],
            12: ["8-ESS3-2", "MS-ETS1-2"],
            13: ["8-ESS3-2", "MS-ETS1-1", "MS-ETS1-2"],
            14: ["8-ESS3-2", "MS-ETS1-1", "MS-ETS1-2"],

            // Unit 3: Earth's Resources & Human Impact (Weeks 15-21)
            15: ["8-ESS1-4", "8-ESS3-1", "8-ESS3-3"],
            16: ["8-ESS1-4", "8-ESS3-1", "8-ESS3-3"],
            17: ["8-ESS3-1", "8-ESS3-4"],
            18: ["8-ESS3-3", "8-ESS3-5", "MS-ETS1-1"],
            19: ["8-ESS3-1", "8-ESS3-3", "8-ESS3-4", "MS-ETS1-1"],
            20: ["8-ESS3-3", "8-ESS3-5", "MS-ETS1-2"],
            21: ["8-ESS3-1", "8-ESS3-3", "8-ESS3-4", "MS-ETS1-2"],

            // Unit 4: Genetics (Weeks 22-28)
            22: ["8-LS1-4", "8-LS1-5",],
            23: ["8-LS1-4", "8-LS1-5"],
            24: ["8-LS2-4", "8-LS2-5", "8-LS4-5"],
            25: ["8-LS2-4", "8-LS2-5", "8-LS4-5"],
            26: ["8-LS3-1", "8-LS3-2", "8-LS4-5"],
            27: ["8-LS3-1", "8-LS3-2", "8-LS4-5"],
            28: ["8-LS3-1", "8-LS3-2", "8-LS4-5"],

            // Unit 5: Natural Selection & Common Ancestry (Weeks 27-36)
            29: ["8-LS1-4", "8-LS4-3"],
            30: ["8-LS1-4", "8-LS4-4"],
            31: ["8-LS4-1", "8-LS4-2", "8-LS4-4"],
            32: ["8-LS4-1", "8-LS4-2", "8-LS4-3"],
            33: ["8-LS4-3", "8-LS4-4"],
            34: ["8-LS4-1", "8-LS4-2", "8-LS4-4"],
            35: ["8-LS4-3", "8-LS4-4"],
            36: ["8-LS4-6"], // Added LS4-6 Natural Selection End
        };*/

        /*
        let pacingGuideStandardsDict = {
            // Unit 1: Marathon Runner & Unit 2: Energy and Matter in Ecosystems (25 days / 5 weeks)
            1: ["B-LS1-7", "B-LS2-3"], // Cellular respiration and matter cycling
            2: ["B-LS1-7", "B-LS2-4"], // Energy transfer focus
            3: ["B-LS1-5", "B-LS1-6"], // Photosynthesis and macromolecules
            4: ["B-LS2-1", "B-LS2-5"], // Ecosystem carrying capacity and carbon cycling
            5: ["B-LS2-7", "B-LS2-2"], // Human impact and biodiversity

            // Unit 3: Evolution of Sick Humans (18 days / ~3.6 weeks)
            6: ["B-LS1-1", "B-LS1-2"], // DNA structure and cell organization
            7: ["B-LS1-1", "B-LS1-3"], // Protein synthesis and homeostasis
            8: ["B-LS4-1", "B-LS2-6"], // Common ancestry and ecosystem interactions
            9: ["B-LS4-1", "B-LS2-8"], // Evolution evidence and group behavior

            // Unit 4: Humans vs. Bacteria (18 days / ~3.6 weeks)
            10: ["B-LS4-2", "B-LS4-3"], // Natural selection principles
            11: ["B-LS4-2", "B-LS4-4"], // Natural selection and adaptation
            12: ["B-LS4-4", "B-LS4-5"], // Adaptation and environmental changes
            13: ["B-LS4-5", "B-LS4-6"], // Species changes and human impact

            // Unit 5: The Biology of Life (18 days / ~3.6 weeks)
            14: ["B-LS1-4", "B-LS3-1"], // Cell division and DNA inheritance
            15: ["B-LS3-2", "B-LS3-3"], // Meiosis and genetic inheritance
            16: ["B-LS3-2", "B-LS3-3"]  // Genetic variation and review
        };
        */

        /*
        // HMH Algebra 1 SC default pacing guide
        let pacingGuideStandardsDict = {
            // First Semester
            // Unit 1: Number and Quantity Foundation (3 weeks)
            1: [
                "A1.NRNS.1*", // Properties of exponents and radicals
                "A1.NRNS.2*", // Rational exponents
                "A1.NQ.1*"    // Units and measurement
            ],
            2: [
                "A1.NQ.2*",   // Quantities in context
                "A1.NQ.3*",   // Accuracy in measurement
                "A1.NRNS.3"   // Properties of rational/irrational numbers
            ],
            3: [
                "A1.ASE.1*",  // Interpret expressions
                "A1.ASE.2*",  // Analyze structure
                "A1.AAPR.1*"  // Operations with polynomials
            ],

            // Unit 2: Equations and Inequalities (4 weeks)
            4: [
                "A1.ACE.1*",  // Create equations one variable
                "A1.ACE.2*",  // Create equations two variables
                "A1.AREI.1*"  // Justify solving steps
            ],
            5: [
                "A1.AREI.3*",  // Linear equations and inequalities
                "A1.AREI.10*", // Graph of equations
                "A1.AREI.12*"  // Linear inequalities in two variables
            ],
            6: [
                "A1.AREI.4.a*", // Quadratic equations - completing square
                "A1.AREI.4.b*", // Quadratic equations - various methods
                "A1.ACE.4*"     // Literal equations
            ],
            7: [
                "A1.AREI.6.a*", // Systems - substitution
                "A1.AREI.6.b*", // Systems - linear combination
                "A1.AREI.5"     // Linear combinations
            ],

            // Unit 3: Functions (4 weeks)
            8: [
                "A1.FIF.1.a*", // Function domain/range
                "A1.FIF.1.b*", // Function notation
                "A1.FIF.1.c*"  // Function graphs
            ],
            9: [
                "A1.FIF.2*",  // Evaluate functions
                "A1.FIF.4*",  // Key features
                "A1.FIF.5*"   // Domain and range relationships
            ],
            10: [
                "A1.FIF.6*",  // Average rate of change
                "A1.FIF.7*",  // Graph functions
                "A1.FIF.8.a*" // Different forms of functions
            ],
            11: [
                "A1.FIF.9*",  // Compare functions
                "A1.FBF.3*",  // Transformations
                "A1.ASE.3.a*" // Equivalent forms
            ],

            // Unit 4: Linear, Quadratic and Exponential Models (4 weeks)
            12: [
                "A1.FLQE.1.a*", // Linear vs exponential
                "A1.FLQE.2*",   // Create functions
                "A1.FLQE.3*"    // Compare growth rates
            ],
            13: [
                "A1.AREI.11*", // Solve f(x) = g(x)
                "A1.FLQE.5*",  // Interpret parameters
                "A1.SPID.6*"   // Scatter plots
            ],
            14: [
                "A1.SPID.7*", // Linear modeling
                "A1.SPID.8*"  // Correlation coefficient
            ],

            // Unit 5: Review and Assessment (4 weeks)
            15: [
                // Number and Equations Review
                "A1.NRNS.2*",
                "A1.ACE.1*",
                "A1.AREI.4.b*"
            ],
            16: [
                // Functions Review
                "A1.FIF.4*",
                "A1.FIF.5*",
                "A1.FIF.6*"
            ],
            17: [
                // Modeling Review
                "A1.FLQE.2*",
                "A1.SPID.6*",
                "A1.SPID.7*"
            ],
            18: [
                // Final Review
                "A1.FIF.9*",
                "A1.AREI.11*",
                "A1.AREI.12*"
            ],

            // Second Semester
            // Unit 6: Advanced Number and Quantity (3 weeks)
            19: [
                "A1.NRNS.1*", // Properties of exponents and radicals
                "A1.NRNS.2*", // Rational exponents
                "A1.NQ.1*"    // Units and measurement
            ],
            20: [
                "A1.NQ.2*",   // Quantities in context
                "A1.NQ.3*",   // Accuracy in measurement
                "A1.NRNS.3"   // Properties of rational/irrational numbers
            ],
            21: [
                "A1.ASE.1*",  // Interpret expressions
                "A1.ASE.2*",  // Analyze structure
                "A1.AAPR.1*"  // Operations with polynomials
            ],

            // Unit 7: Advanced Equations and Inequalities (4 weeks)
            22: [
                "A1.ACE.1*",  // Create equations one variable
                "A1.ACE.2*",  // Create equations two variables
                "A1.AREI.1*"  // Justify solving steps
            ],
            23: [
                "A1.AREI.3*",  // Linear equations and inequalities
                "A1.AREI.10*", // Graph of equations
                "A1.AREI.12*"  // Linear inequalities in two variables
            ],
            24: [
                "A1.AREI.4.a*", // Quadratic equations - completing square
                "A1.AREI.4.b*", // Quadratic equations - various methods
                "A1.ACE.4*"     // Literal equations
            ],
            25: [
                "A1.AREI.6.a*", // Systems - substitution
                "A1.AREI.6.b*", // Systems - linear combination
                "A1.AREI.5"     // Linear combinations
            ],

            // Unit 8: Advanced Functions (4 weeks)
            26: [
                "A1.FIF.1.a*", // Function domain/range
                "A1.FIF.1.b*", // Function notation
                "A1.FIF.1.c*"  // Function graphs
            ],
            27: [
                "A1.FIF.2*",  // Evaluate functions
                "A1.FIF.4*",  // Key features
                "A1.FIF.5*"   // Domain and range relationships
            ],
            28: [
                "A1.FIF.6*",  // Average rate of change
                "A1.FIF.7*",  // Graph functions
                "A1.FIF.8.a*" // Different forms of functions
            ],
            29: [
                "A1.FIF.9*",  // Compare functions
                "A1.FBF.3*",  // Transformations
                "A1.ASE.3.a*" // Equivalent forms
            ],

            // Unit 9: Advanced Modeling (4 weeks)
            30: [
                "A1.FLQE.1.a*", // Linear vs exponential
                "A1.FLQE.2*",   // Create functions
                "A1.FLQE.3*"    // Compare growth rates
            ],
            31: [
                "A1.AREI.11*", // Solve f(x) = g(x)
                "A1.FLQE.5*",  // Interpret parameters
                "A1.SPID.6*"   // Scatter plots
            ],
            32: [
                "A1.SPID.7*", // Linear modeling
                "A1.SPID.8*"  // Correlation coefficient
            ],

            // Unit 10: Final Review and Assessment (4 weeks)
            33: [
                // Advanced Number and Equations Review
                "A1.NRNS.2*",
                "A1.ACE.1*",
                "A1.AREI.4.b*"
            ],
            34: [
                // Advanced Functions Review
                "A1.FIF.4*",
                "A1.FIF.5*",
                "A1.FIF.6*"
            ],
            35: [
                // Advanced Modeling Review
                "A1.FLQE.2*",
                "A1.SPID.6*",
                "A1.SPID.7*"
            ],
            36: [
                // Final Comprehensive Review
                "A1.FIF.9*",
                "A1.AREI.11*",
                "A1.AREI.12*"
            ]
        };
        */

        // Biology SC default pacing guide
        let pacingGuideStandardsDict = {
            // First Semester
            // Unit 1: Energy and Matter in Cells
            1: ["B-LS1-7"], // Cellular respiration
            2: ["B-LS1-7", "B-LS1-5"], // Cellular respiration and photosynthesis
            3: ["B-LS1-5", "B-LS1-6"], // Photosynthesis and molecular building blocks
            4: ["B-LS2-1", "B-LS2-5"], // Ecosystem carrying capacity and carbon cycling
            5: ["B-LS2-7"], // Human impacts on ecosystems

            // Unit 2: DNA and Proteins
            6: ["B-LS1-1"], // DNA structure and protein synthesis
            7: ["B-LS1-1"], // DNA and protein functions

            // Unit 3: Evolution and Natural Selection
            8: ["B-LS4-1"], // Evidence for evolution
            9: ["B-LS4-1"], // Common ancestry
            10: ["B-LS4-2"], // Factors in evolution
            11: ["B-LS4-2", "B-LS4-4"], // Natural selection
            12: ["B-LS4-4", "B-LS4-2"], // Adaptation of populations
            13: ["B-LS4-5"], // Environmental changes and species

            // Unit 4: Cell Division and Genetics
            14: ["B-LS1-4", "B-LS3-2"], // Mitosis and genetic variation
            15: ["B-LS3-2"], // Inheritance patterns
            16: ["B-LS3-2", "B-LS3-3"], // Genetic variation and traits
            17: ["B-LS3-3"], // Population genetics
            18: [], // Review and Assessment

            // Second Semester - Advanced Topics
            // Unit 5: Advanced Energy and Matter in Cells
            19: ["B-LS1-7"], // Advanced cellular respiration concepts
            20: ["B-LS1-7", "B-LS1-5"], // Advanced cell energy processes
            21: ["B-LS1-5", "B-LS1-6"], // Advanced molecular biology
            22: ["B-LS2-1", "B-LS2-5"], // Complex ecosystem interactions
            23: ["B-LS2-7"], // Advanced ecological impacts

            // Unit 6: Advanced DNA and Proteins
            24: ["B-LS1-1"], // Advanced molecular genetics
            25: ["B-LS1-1"], // Advanced protein synthesis

            // Unit 7: Advanced Evolution and Natural Selection
            26: ["B-LS4-1"], // Detailed evolutionary evidence
            27: ["B-LS4-1"], // Advanced phylogenetics
            28: ["B-LS4-2"], // Complex evolutionary processes
            29: ["B-LS4-2", "B-LS4-4"], // Advanced natural selection
            30: ["B-LS4-4", "B-LS4-2"], // Population adaptation mechanisms
            31: ["B-LS4-5"], // Species response to change

            // Unit 8: Advanced Cell Division and Genetics
            32: ["B-LS1-4", "B-LS3-2"], // Advanced cell division
            33: ["B-LS3-2"], // Complex inheritance
            34: ["B-LS3-2", "B-LS3-3"], // Advanced genetic analysis
            35: ["B-LS3-3"], // Advanced population genetics
            36: [] // Final Review and Assessment
        };



        function dedupPacingGuide(pacingGuide) {
            const dedupedGuide = {};

            for (const [week, standards] of Object.entries(pacingGuide)) {
                dedupedGuide[week] = [...new Set(standards)];
            }

            return dedupedGuide;
        }

        // remove duplicate standard names from each week if they exist
        pacingGuideStandardsDict = dedupPacingGuide(pacingGuideStandardsDict);

        // ensure all standards exist in standardsDict
        for (let week in pacingGuideStandardsDict) {
            for (let standard of pacingGuideStandardsDict[week]) {
                if (!this.state.standardsDict.hasOwnProperty(standard)) {
                    console.error('standard does not exist', standard)
                    return
                }
            }
        }

        // sort by category
        /*for (let week in pacingGuideStandardsDict) {
            pacingGuideStandardsDict[week].sort((a, b) => {
                if (a.includes('RL') && !b.includes('RL')) {
                    return -1;
                }
                if (!a.includes('RL') && b.includes('RL')) {
                    return 1;
                }
                if (a.includes('RL') && b.includes('RL')) {
                    return a.localeCompare(b);
                }
                if (a.includes('RI') && !b.includes('RI')) {
                    return -1;
                }
                if (!a.includes('RI') && b.includes('RI')) {
                    return 1;
                }
                if (a.includes('RI') && b.includes('RI')) {
                    return a.localeCompare(b);
                }
                // AOR > C > F > R
                if (a.includes('AOR') && !b.includes('AOR')) {
                    return -1;
                }
                if (!a.includes('AOR') && b.includes('AOR')) {
                    return 1;
                }
                if (a.includes('C') && !b.includes('C')) {
                    return -1;
                }
                if (!a.includes('C') && b.includes('C')) {
                    return 1;
                }
                if (a.includes('C') && b.includes('C')) {
                    return a.localeCompare(b);
                }
                if (a.includes('F') && !b.includes('F')) {
                    return -1;
                }
                if (!a.includes('F') && b.includes('F')) {
                    return 1;
                }
                if (a.includes('R') && !b.includes('R')) {
                    return -1;
                }
                if (!a.includes('R') && b.includes('R')) {
                    return 1;
                }

                // both have AOR, string split and convert [3] to number and compare, if the same, lexical
                if (a.includes('AOR') && b.includes('AOR')) {
                    let partsA = a.split('.');
                    let partsB = b.split('.');
                    if (partsA.length > 3 && partsB.length > 3) {
                        let numA = parseInt(partsA[3]);
                        let numB = parseInt(partsB[3]);
                        if (numA !== numB) {
                            return numA - numB;
                        }
                    }
                }
                return a.localeCompare(b);
            });
        }*/

        let standardsScheduled = {}
        let weeks = {}
        for (let i = 0; i < this.state.totalWeeks; i++) {
            weeks[i] = []
        }

        for (let week in pacingGuideStandardsDict) {
            for (let standard of pacingGuideStandardsDict[week]) {
                weeks[parseInt(week)].push(this.state.standardsDict[standard])
                standardsScheduled[standard] = true
            }
        }

        // console.log if not all standards are scheduled
        let notAllScheduled = false
        for (let i = 0; i < this.state.standards.length; i++) {
            const standard = this.state.standards[i].standard
            if (!standardsScheduled.hasOwnProperty(standard)) {
                console.error('standard not scheduled', standard)
                notAllScheduled = true
            }
        }

        if (notAllScheduled) {
            console.error('not all standards scheduled, returning');
            return;
        }

        console.log('all standards scheduled, all standards exist');

        this.setWeeks(weeks);
    }

    /*setDefaultPacingGuide = () => {
        if (this.state.standards.length === 0) {
            return;
        }

        let standards = JSON.parse(JSON.stringify(this.state.standards));

        // Group the standards by their category
        const groupedStandards = standards.reduce((acc, curr) => {
            // ELA is [0], ELA K-2 is [2], Math is [1], change for the subject and grade level
            let category = curr.standard.split('.');
            category = category[1]

            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(curr);
            return acc;
        }, {});

        // Sort each group
        for (const category in groupedStandards) {
            groupedStandards[category].sort((a, b) => {
                const partsA = a.standard.split('.');
                const partsB = b.standard.split('.');
                for (let i = 1; i < Math.min(partsA.length, partsB.length); i++) {
                    if (partsA[i] !== partsB[i]) {
                        if (isNaN(partsA[i]) || isNaN(partsB[i])) {
                            return partsA[i].localeCompare(partsB[i]);
                        }
                        return parseInt(partsA[i]) - parseInt(partsB[i]);
                    }
                }
                return partsA.length - partsB.length;
            });
        }

        console.log(groupedStandards)

        const weeks = Array.from({ length: 37 }, () => []);

        // Distribute each standard to cover all 36 weeks
        for (const category in groupedStandards) {
            let categoryStandards = groupedStandards[category];
            let numStandards = categoryStandards.length;
            let distributionSpan = Math.floor(36 / numStandards); // Span of weeks for each standard
            if (numStandards > 36) {
                distributionSpan = 1;
            }
            console.log(category, distributionSpan)
            if (distributionSpan > 30) {
                //distributionSpan /= 10;
                //distributionSpan = Math.floor(distributionSpan);
            }
            let weekIdx = 1;
            for (let i = 0; i < numStandards; i++) {
                let standard = categoryStandards[i];
                if (weekIdx + distributionSpan > 36) {
                    const weeksLeft = 36 - weekIdx + 1;
                    for (let j = 0; j < weeksLeft; j++) {
                        weeks[weekIdx + j].push(standard);
                    }
                    for (let standard of categoryStandards) {
                        if (this.isStandardScheduled(weeks, standard)) {
                            continue;
                        }
                        weeks[36].push(standard);
                    }
                    break;
                }
                for (let j = 0; j < distributionSpan; j++) {
                    const week = weekIdx + j;
                    if (week > 36) {
                        break;
                    }
                    weeks[week].push(standard);
                    if (category === 'RL' ||
                        category === 'RI' ||
                        category === 'F' ||
                        category === 'AOR' ||
                        category === 'NS' ||
                        category === 'ATO' ||
                        category === 'NSBT' ||
                        category === 'G' ||
                        category === 'H' ||
                        category == 'AG' ||
                        category == 'ER' ||
                        category == 'PS4' ||
                        category === 'LS1') {
                        if ((distributionSpan === 1 && i < numStandards - 1) ||
                            (j > 0 && i < numStandards - 1)) {
                            //(numStandards > 36 && i < numStandards - 1)) {
                            weeks[week].push(categoryStandards[i + 1]);
                        }
                    }
                }
                weekIdx += distributionSpan;
            }
        }

        for (let i = 1; i < 37; i++) {
            weeks[i].sort((a, b) => {
                // RL, RI, W, I, C
                let partsA = a.standard.split('.');
                let partsB = b.standard.split('.');
                if (partsA[0] === 'RL' && partsB[0] !== 'RL') {
                    return -1;
                } else if (partsA[0] !== 'RL' && partsB[0] === 'RL') {
                    return 1;
                } else if (partsA[0] === 'RI' && partsB[0] !== 'RI') {
                    return -1;
                } else if (partsA[0] !== 'RI' && partsB[0] === 'RI') {
                    return 1;
                } else if (partsA[0] === 'W' && partsB[0] !== 'W') {
                    return -1;
                } else if (partsA[0] !== 'W' && partsB[0] === 'W') {
                    return 1;
                } else if (partsA[0] === 'I' && partsB[0] !== 'I') {
                    return -1;
                } else if (partsA[0] !== 'I' && partsB[0] === 'I') {
                    return 1;
                } else if (partsA[0] === 'C' && partsB[0] !== 'C') {
                    return -1;
                } else if (partsA[0] !== 'C' && partsB[0] === 'C') {
                    return 1;
                }

                partsA = a.standard.split('.')[1];
                partsB = b.standard.split('.')[1];
                if (partsA === 'ATO' && partsB !== 'ATO') {
                    return -1;
                } else if (partsA !== 'ATO' && partsB === 'ATO') {
                    return 1;
                } else if (partsA === 'NSBT' && partsB !== 'NSBT') {
                    return -1;
                } else if (partsA !== 'NSBT' && partsB === 'NSBT') {
                    return 1;
                } else if (partsA === 'G' && partsB !== 'G') {
                    return -1;
                } else if (partsA !== 'G' && partsB === 'G') {
                    return 1;
                } else {
                    return 0;
                }

                return 0;
            });
        }

        weeks[0] = JSON.parse(JSON.stringify(standards)); // Optionally keep a copy of all standards
        this.setWeeks(weeks);
    }*/

    setFuturePacingGuide = () => {
        if (!this.state.futureStandards ||
            this.state.futureStandards.length === 0 ||
            !this.state.futureStandardsDict) {
            return;
        }

        let pacingGuideStandardsDict = {
            // Unit 1: Area and Surface Area (4 weeks)
            1: ["KY.6.G.1", "KY.6.EE.2.a", "KY.6.EE.2.c"],
            2: ["KY.6.G.1", "KY.6.EE.2.a", "KY.6.EE.2.c", "KY.6.G.4"],
            3: ["KY.6.G.1", "KY.6.G.4", "KY.6.G.2"],
            4: ["KY.6.G.1", "KY.6.G.4", "KY.6.EE.1", "KY.6.EE.2.a"],

            // Unit 2: Introducing Ratios (4 weeks)
            5: ["KY.6.RP.1"],
            6: ["KY.6.RP.1", "KY.6.RP.3.a"],
            7: ["KY.6.RP.3.a", "KY.6.RP.3.b"],
            8: ["KY.6.RP.2", "KY.6.RP.3.a", "KY.6.RP.3.b"],

            // Unit 3: Unit Rate and Percentages (4 weeks)
            9: ["KY.6.RP.2", "KY.6.RP.3.b"],
            10: ["KY.6.RP.2", "KY.6.RP.3.b"],
            11: ["KY.6.RP.3.c"],
            12: ["KY.6.RP.3.c"],

            // Unit 4: Dividing Fractions (4 weeks)
            13: ["KY.6.NS.1"],
            14: ["KY.6.NS.1", "KY.6.G.1", "KY.6.G.2"],
            15: ["KY.6.NS.1", "KY.6.G.2"],
            16: ["KY.6.NS.1"],

            // Unit 5: Arithmetic in Base Ten (4 weeks)
            17: ["KY.6.NS.3"],
            18: ["KY.6.NS.2.a", "KY.6.NS.2.b", "KY.6.NS.3"],
            19: ["KY.6.NS.2.a", "KY.6.NS.2.b", "KY.6.NS.3"],
            20: ["KY.6.EE.4", "KY.6.NS.3"],

            // Unit 6: Expressions and Equations (4 weeks)
            21: ["KY.6.EE.5", "KY.6.EE.6", "KY.6.EE.7"],
            22: ["KY.6.EE.2.a", "KY.6.EE.2.c", "KY.6.EE.6", "KY.6.EE.4"],
            23: ["KY.6.EE.1", "KY.6.EE.2.b", "KY.6.EE.3"],
            24: ["KY.6.EE.1", "KY.6.EE.2.c", "KY.6.EE.5", "KY.6.EE.9.a", "KY.6.EE.9.b", "KY.6.EE.9.c"],

            // Unit 7: Rational Numbers (4 weeks)
            25: ["KY.6.NS.5", "KY.6.NS.6.a", "KY.6.NS.7.a"],
            26: ["KY.6.NS.7.b", "KY.6.NS.5"],
            27: ["KY.6.NS.7.c", "KY.6.NS.7.d", "KY.6.NS.6.a", "KY.6.EE.8"],
            28: ["KY.6.NS.6.b", "KY.6.NS.6.c", "KY.6.NS.8", "KY.6.G.3", "KY.6.NS.4"],

            // Unit 8: Data Sets and Distributions (4 weeks)
            29: ["KY.6.SP.0.a", "KY.6.SP.0.b", "KY.6.SP.1", "KY.6.SP.5.b"],
            30: ["KY.6.SP.2", "KY.6.SP.0.c", "KY.6.SP.4"],
            31: ["KY.6.SP.3", "KY.6.SP.0.d", "KY.6.SP.5.c"],
            32: ["KY.6.SP.5.b", "KY.6.SP.5.c", "KY.6.SP.5.d", "KY.6.SP.1", "KY.6.SP.4", "KY.6.SP.5.a", "KY.6.SP.5.e"],

            // Unit 9: Putting It All Together (4 weeks)
            33: ["KY.6.G.1", "KY.6.NS.2.a", "KY.6.RP.1"],
            34: ["KY.6.NS.2.b", "KY.6.RP.1", "KY.6.NS.1"],
            35: ["KY.6.RP.1", "KY.6.RP.3.a", "KY.6.RP.3.c"],
            36: ["KY.6.NS.3", "KY.6.RP.2", "KY.6.RP.3.a", "KY.6.RP.3.c"]
        };

        // ensure all standards exist in futureStandardsDict
        for (let week in pacingGuideStandardsDict) {
            for (let standard of pacingGuideStandardsDict[week]) {
                if (!this.state.futureStandardsDict.hasOwnProperty(standard)) {
                    console.error('standard does not exist', standard)
                    return
                }
            }
        }

        // see if all are scheduled
        let scheduled = Object.assign({}, this.state.futureStandardsDict);
        for (let week in pacingGuideStandardsDict) {
            for (let standard of pacingGuideStandardsDict[week]) {
                delete scheduled[standard];
            }
        }
        if (Object.keys(scheduled).length > 0) {
            console.error('standards not scheduled', scheduled)
            return;
        }

        let weeks = {}
        for (let i = 0; i < this.state.totalWeeks; i++) {
            weeks[i] = []
        }

        for (let week in pacingGuideStandardsDict) {
            for (let standard of pacingGuideStandardsDict[week]) {
                weeks[parseInt(week)].push(this.state.futureStandardsDict[standard])
            }
        }

        let futurePacingGuide = {
            standardName: this.state.standardName,
            standardCategory: this.state.standardCategory,
            state: 'SC',
            year: '2023',
            weeks: weeks,
            timeStamp: firebase.firestore.FieldValue.serverTimestamp()
        }

        if (this.state.futurePacingGuide) {
            db.collection(ColType.futurePacingGuides)
                .doc(this.state.futurePacingGuide.id)
                .set(futurePacingGuide, { merge: true })
                .then(() => {
                    //console.log('Future pacing guide updated')
                })
                .catch(error => {
                    //console.error('Error updating future pacing guide', error)
                })
        } else {
            db.collection(ColType.futurePacingGuides)
                .add(futurePacingGuide)
                .then(docRef => {
                    //console.log('Future pacing guide added', docRef.id)
                    futurePacingGuide.id = docRef.id;
                    this.setState({ futurePacingGuide: futurePacingGuide })
                })
                .catch(error => {
                    console.error('Error adding future pacing guide', error)
                })
        }
    }


    copyWeeksToTargetWeek = (weekStart, weekEnd, targetWeekStart) => {
        //console.log('copyWeeksToTargetWeek', weekStart, weekEnd, targetWeekStart)
        const weekDiff = weekEnd - weekStart;
        if (targetWeekStart + weekDiff > 36) {
            //console.log('target week > 36')
            return;
        }
        let pacingGuide = this.state.pacingGuide;
        for (let i = 0; i <= weekDiff; i++) {
            pacingGuide.weeks[targetWeekStart + i] = pacingGuide.weeks[weekStart + i];
        }
        this.setWeeks(pacingGuide.weeks);
    }

    setWeeksToStandards = (standardName, weeksDict) => {
        if (standardName !== this.state.standardName) {
            //console.log('standard name mismatch')
            return;
        }
        let pacingGuide = this.state.pacingGuide;
        Object.keys(weeksDict).map((key) => {
            const weekStandards = weeksDict[key];
            const week = parseInt(key);
            pacingGuide.weeks[week] = [];
            for (let i = 0; i < weekStandards.length; i++) {
                const weekStandard = weekStandards[i];
                if (!this.state.standardsDict.hasOwnProperty(weekStandard)) {
                    //console.log('standard not found', weekStandard, weekStandards)
                    return false
                }
                const standard = this.state.standardsDict[weekStandard];
                pacingGuide.weeks[week].push(standard);
            }
            return false;
        })
        this.setWeeks(pacingGuide.weeks);
    }

    /*shiftWeeksDown = () => {
        let pacingGuide = this.state.pacingGuide;
        let weeks = pacingGuide.weeks;
        const maxWeek = this.state.totalWeeks - 1;

        for (let i = maxWeek; i >= 2; i--) {
            weeks[i] = weeks[i - 1];
        }

        weeks[1] = [];

        //this.setWeeks(weeks);
    }*/

    render() {
        const { match: { url } } = this.props

        return (
            <div>
                <Route path={`${url}/guide/*`}
                    render={props => PacingGuideComponent(props, this.props, this.state)} />
                <Route path={`${url}/lesson-plan/*`}
                    render={props => LessonPlanComponent(props, this.props, this.state)} />
                <Route path={`${url}/weekly-planner/*`}
                    render={props => WeeklyPlannerComponent(props, this.props, this.state)} />

                {/*<Button style={{
                    position: 'fixed',
                    top: '88px',
                }}
                    onClick={() => {
                        //this.setPacingGuideWithPresetStandards()
                        //this.setFuturePacingGuide()
                        //this.shiftWeeksDown()
                        //this.updatePacingGuideStandards()
                    }}
                >
                    Set pacing guide with preset standards
                </Button>*/}
            </div>
        )
    }
}

export default PacingGuide