import React from 'react';
import { Card, Button } from 'antd';
import { gradeNameSort } from '../Util';

const GradeColors = {
    exceeds: '#389e0d',     // Green
    meets: '#096dd9',       // Blue
    approaches: '#d48806',  // Orange
    does_not_meet: '#cf1322' // Red
};

class AssessmentBreakdownView extends React.Component {
    state = {
        expandedSchools: new Set(),
        expandedSubjects: new Set(),
        expandedGrades: new Set(),
        selectedCategory: 'all',
        showPercentages: false
    };

    calculateStats() {
        const { assessmentData } = this.props;
        const { selectedCategory } = this.state;
        const stats = {};

        const filteredAssessments = assessmentData.filter(assessment =>
            selectedCategory === 'all' || assessment.metadata?.assessmentCategory === selectedCategory
        );

        filteredAssessments.forEach(assessment => {
            const quarter = this.getQuarter(assessment.metadata?.endWeek);
            if (!quarter) return;

            assessment.schools?.forEach(school => {
                if (!stats[school.schoolId]) {
                    stats[school.schoolId] = {
                        name: school.schoolName || 'Unknown School',
                        subjects: {},
                        quarterlyGrades: {
                            Q1: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 },
                            Q2: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 },
                            Q3: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 },
                            Q4: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 }
                        }
                    };
                }

                school.subjects?.forEach(subject => {
                    if (!stats[school.schoolId].subjects[subject.name]) {
                        stats[school.schoolId].subjects[subject.name] = {
                            name: subject.name,
                            grades: {},
                            quarterlyGrades: {
                                Q1: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 },
                                Q2: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 },
                                Q3: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 },
                                Q4: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 }
                            }
                        };
                    }

                    subject.grades?.forEach(grade => {
                        const gradeKey = grade.name;
                        if (!stats[school.schoolId].subjects[subject.name].grades[gradeKey]) {
                            stats[school.schoolId].subjects[subject.name].grades[gradeKey] = {
                                name: grade.name,
                                teachers: {},
                                quarterlyGrades: {
                                    Q1: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 },
                                    Q2: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 },
                                    Q3: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 },
                                    Q4: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 }
                                }
                            };
                        }

                        const gradeStats = stats[school.schoolId].subjects[subject.name].grades[gradeKey];

                        grade.teachers?.forEach(teacher => {
                            if (!gradeStats.teachers[teacher.teacherId]) {
                                gradeStats.teachers[teacher.teacherId] = {
                                    teacherId: teacher.teacherId,
                                    quarterlyGrades: {
                                        Q1: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 },
                                        Q2: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 },
                                        Q3: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 },
                                        Q4: { does_not_meet: 0, approaches: 0, meets: 0, exceeds: 0 }
                                    }
                                };
                            }

                            const categories = teacher.stats?.grades?.categories || {};
                            const teacherCurrentGrades = gradeStats.teachers[teacher.teacherId].quarterlyGrades[quarter];

                            Object.entries(categories).forEach(([category, count]) => {
                                const newCount = (teacherCurrentGrades[category] || 0) + count;
                                gradeStats.teachers[teacher.teacherId].quarterlyGrades[quarter][category] = newCount;
                                gradeStats.quarterlyGrades[quarter][category] += count;
                                stats[school.schoolId].subjects[subject.name].quarterlyGrades[quarter][category] += count;
                                stats[school.schoolId].quarterlyGrades[quarter][category] += count;
                            });
                        });
                    });
                });
            });
        });

        return Object.values(stats).sort((a, b) => a.name.localeCompare(b.name));
    }


    handleCategoryChange = (category) => {
        this.setState({ selectedCategory: category });
    };

    getQuarter(week) {
        if (week <= 9) return 'Q1';
        if (week <= 18) return 'Q2';
        if (week <= 27) return 'Q3';
        return 'Q4';
    }

    toggleSchool = (schoolName) => {
        this.setState(prevState => ({
            expandedSchools: new Set(
                prevState.expandedSchools.has(schoolName)
                    ? Array.from(prevState.expandedSchools).filter(name => name !== schoolName)
                    : [...prevState.expandedSchools, schoolName]
            )
        }));
    };

    toggleSubject = (key) => {
        this.setState(prevState => ({
            expandedSubjects: new Set(
                prevState.expandedSubjects.has(key)
                    ? Array.from(prevState.expandedSubjects).filter(k => k !== key)
                    : [...prevState.expandedSubjects, key]
            )
        }));
    };

    toggleGrade = (key) => {
        this.setState(prevState => ({
            expandedGrades: new Set(
                prevState.expandedGrades.has(key)
                    ? Array.from(prevState.expandedGrades).filter(k => k !== key)
                    : [...prevState.expandedGrades, key]
            )
        }));
    };

    expandAll = () => {
        const stats = this.calculateStats();
        const allSchools = new Set();
        const allSubjects = new Set();
        const allGrades = new Set();

        stats.forEach(school => {
            allSchools.add(school.name);
            Object.entries(school.subjects).forEach(([subject, subjectData]) => {
                const subjectKey = `${school.name}-${subject}`;
                allSubjects.add(subjectKey);

                Object.keys(subjectData.grades).forEach(grade => {
                    allGrades.add(`${subjectKey}-${grade}`);
                });
            });
        });

        this.setState({
            expandedSchools: allSchools,
            expandedSubjects: allSubjects,
            expandedGrades: allGrades
        });
    };

    collapseAll = () => {
        this.setState({
            expandedSchools: new Set(),
            expandedSubjects: new Set(),
            expandedGrades: new Set()
        });
    };

    calculatePercentages(grades) {
        const total = grades.does_not_meet + grades.approaches + grades.meets + grades.exceeds;
        if (total === 0) return grades;

        return {
            does_not_meet: (grades.does_not_meet / total) * 100,
            approaches: (grades.approaches / total) * 100,
            meets: (grades.meets / total) * 100,
            exceeds: (grades.exceeds / total) * 100
        };
    }

    toggleDisplayMode = () => {
        this.setState(prev => ({ showPercentages: !prev.showPercentages }));
    };

    renderGradeCell = (grades, quarter) => {
        const { showPercentages } = this.state;
        const displayGrades = showPercentages ? this.calculatePercentages(grades) : grades;

        if (!grades.does_not_meet && !grades.approaches && !grades.meets && !grades.exceeds) {
            return (
                <>
                    <td className="grade-cell quarter-start">-</td>
                    <td className="grade-cell">-</td>
                    <td className="grade-cell">-</td>
                    <td className="grade-cell">-</td>
                </>
            );
        }

        const formatValue = (value) => {
            if (showPercentages) {
                return Math.round(value) + '%';
            }
            return value.toString().padStart(3);
        };

        return (
            <>
                <td className="grade-cell quarter-start" style={{ color: displayGrades.exceeds ? GradeColors.exceeds : '#00000040' }}>
                    {formatValue(displayGrades.exceeds)}
                </td>
                <td className="grade-cell" style={{ color: displayGrades.meets ? GradeColors.meets : '#00000040' }}>
                    {formatValue(displayGrades.meets)}
                </td>
                <td className="grade-cell" style={{ color: displayGrades.approaches ? GradeColors.approaches : '#00000040' }}>
                    {formatValue(displayGrades.approaches)}
                </td>
                <td className="grade-cell" style={{ color: displayGrades.does_not_meet ? GradeColors.does_not_meet : '#00000040' }}>
                    {formatValue(displayGrades.does_not_meet)}
                </td>
            </>
        );
    };

    renderHierarchyRow = (item, level, key, hasChildren = false) => {
        const { teacherData } = this.props;
        const isExpanded = level === 'school' ? this.state.expandedSchools.has(item.name) :
            level === 'subject' ? this.state.expandedSubjects.has(key) :
                level === 'grade' ? this.state.expandedGrades.has(key) : false;

        const toggleExpand = level === 'school' ? () => this.toggleSchool(item.name) :
            level === 'subject' ? () => this.toggleSubject(key) :
                level === 'grade' ? () => this.toggleGrade(key) : null;

        const indent = level === 'subject' ? 20 : level === 'grade' ? 40 : level === 'teacher' ? 60 : 0;
        const displayName = level === 'teacher' ? teacherData[item.teacherId]?.fullName : item.name;

        return (
            <tr key={key} style={{ backgroundColor: level === 'teacher' ? '#fafafa' : 'white' }}>
                <td style={{
                    padding: '12px',
                    width: '250px',
                    whiteSpace: 'nowrap'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: `${indent}px`
                    }}>
                        {hasChildren && (
                            <span
                                style={{ cursor: 'pointer', marginRight: '8px', color: '#666' }}
                                onClick={toggleExpand}
                            >
                                {isExpanded ? '▼' : '►'}
                            </span>
                        )}
                        <span style={{
                            fontWeight: level === 'school' ? '500' : '400',
                            color: level === 'teacher' ? '#666' : '#000'
                        }}>
                            {displayName}
                        </span>
                    </div>
                </td>
                {['Q1', 'Q2', 'Q3', 'Q4'].map((quarter) =>
                    this.renderGradeCell(item.quarterlyGrades[quarter], quarter)
                )}
            </tr>
        );
    };

    render() {
        const stats = this.calculateStats();
        const { selectedCategory } = this.state;

        return (
            <Card
                title="Assessment Grade Breakdown"
                extra={
                    <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                        <div style={{
                            display: 'flex',
                            gap: '8px',
                            borderRight: '1px solid #e8e8e8',
                            paddingRight: '16px'
                        }}>
                            {[
                                { value: 'all', label: 'All' },
                                { value: 'common_assessment', label: 'Common Assessments' },
                                { value: 'benchmark', label: 'Benchmarks' }
                            ].map(option => (
                                <Button
                                    key={option.value}
                                    onClick={() => this.handleCategoryChange(option.value)}
                                    type={selectedCategory === option.value ? 'primary' : 'default'}
                                >
                                    {option.label}
                                </Button>
                            ))}
                        </div>
                        <div style={{ display: 'flex', gap: '8px', borderRight: '1px solid #e8e8e8', paddingRight: '16px' }}>

                            <Button
                                onClick={() => this.setState({ showPercentages: false })}
                                type={!this.state.showPercentages ? 'primary' : 'default'}
                            >
                                Counts
                            </Button>
                            <Button
                                onClick={() => this.setState({ showPercentages: true })}
                                type={this.state.showPercentages ? 'primary' : 'default'}
                            >
                                Percentages
                            </Button>
                        </div>
                        <div>
                            <Button onClick={this.expandAll} style={{ marginRight: '8px' }}>Expand All</Button>
                            <Button onClick={this.collapseAll}>Collapse All</Button>
                        </div>
                    </div>
                }
            >
                <div style={{ overflowX: 'auto' }}>
                    <table style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        fontSize: '14px'
                    }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #e8e8e8' }}>
                                <th rowSpan="2" style={{
                                    textAlign: 'left',
                                    padding: '12px',
                                    backgroundColor: '#fafafa',
                                    fontWeight: 500
                                }}>
                                    Name
                                </th>
                                {['Q1', 'Q2', 'Q3', 'Q4'].map(quarter => (
                                    <th key={quarter} colSpan="4" style={{
                                        padding: '8px',
                                        backgroundColor: '#fafafa',
                                        fontWeight: 500,
                                        textAlign: 'center'
                                    }}>
                                        {quarter}
                                    </th>
                                ))}
                            </tr>
                            <tr style={{ borderBottom: '1px solid #e8e8e8' }}>
                                {Array(4).fill().map((_, i) => (
                                    <React.Fragment key={i}>
                                        <th className="grade-header" style={{ color: GradeColors.exceeds }}>Exceed</th>
                                        <th className="grade-header" style={{ color: GradeColors.meets }}>Meet</th>
                                        <th className="grade-header" style={{
                                            color: GradeColors.approaches,
                                            fontSize: '0.75rem',
                                            verticalAlign: 'bottom'
                                        }}>Approach</th>
                                        <th className="grade-header" style={{ color: GradeColors.does_not_meet }}>DNM</th>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {stats.map(school => {
                                const rows = [];
                                rows.push(this.renderHierarchyRow(school, 'school', school.name, true));

                                if (this.state.expandedSchools.has(school.name)) {
                                    Object.entries(school.subjects).forEach(([subjectName, subject]) => {
                                        const subjectKey = `${school.name}-${subjectName}`;
                                        rows.push(this.renderHierarchyRow(subject, 'subject', subjectKey, true));
                                        if (this.state.expandedSubjects.has(subjectKey)) {
                                            Object.keys(subject.grades).sort(gradeNameSort).forEach(gradeName => {
                                                let grade = subject.grades[gradeName];
                                                const gradeKey = `${subjectKey}-${gradeName}`;
                                                rows.push(this.renderHierarchyRow(grade, 'grade', gradeKey, true));

                                                if (this.state.expandedGrades.has(gradeKey)) {
                                                    Object.keys(grade.teachers).sort((a, b) => {
                                                        const teacherA = this.props.teacherData[a];
                                                        const teacherB = this.props.teacherData[b];
                                                        return teacherA?.lastName.localeCompare(teacherB?.lastName);
                                                    }).forEach(teacherId => {
                                                        let teacher = grade.teachers[teacherId];
                                                        rows.push(this.renderHierarchyRow(
                                                            teacher,
                                                            'teacher',
                                                            `${gradeKey}-${teacherId}`,
                                                            false
                                                        ));
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }

                                return rows;
                            })}
                        </tbody>
                    </table>
                </div>
                <div style={{
                    marginTop: '16px',
                    fontSize: '0.85rem',
                    fontFamily: 'monospace',
                    color: '#666'
                }}>
                    <span style={{ marginRight: '24px', color: '#cf1322' }}>
                        DNM = Does Not Meet
                    </span>
                </div>
                <style>{`
                    .grade-cell {
                        padding: 12px;
                        text-align: center;
                        min-width: 50px;
                        font-family: monospace;
                        font-size: 0.85rem;
                    }
                    .grade-cell.quarter-start {
                        border-left: 2px solid #e8e8e8;
                    }

                    .grade-header {
                        padding: 8px;
                        background-color: #fafafa;
                        font-weight: normal;
                        text-align: center;
                        font-size: 0.85rem;
                    }
                `}</style>
            </Card>
        );
    }
}

export default AssessmentBreakdownView;
