import * as XLSX from 'xlsx';

const formatDateTime = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleString();
};

const formatQuarterData = (data) => {
    return ['Q1', 'Q2', 'Q3', 'Q4'].map(q => data[q] || 0);
};

export const exportDistrictStats = (assessmentData, teacherData, learningReports, iepData, coachingNotes) => {
    const workbook = XLSX.utils.book_new();

    // Export Assessment Counts
    const assessmentCountsData = processAssessmentCounts(assessmentData, teacherData);
    const assessmentCountsSheet = XLSX.utils.aoa_to_sheet([
        ['School', 'Subject', 'Grade', 'Teacher', 'Q1', 'Q2', 'Q3', 'Q4'],
        ...assessmentCountsData
    ]);
    XLSX.utils.book_append_sheet(workbook, assessmentCountsSheet, 'Assessment Counts');

    // Export Student Voice Data
    const studentVoiceData = processStudentVoice(assessmentData, teacherData);
    const studentVoiceSheet = XLSX.utils.aoa_to_sheet([
        ['School', 'Subject', 'Grade', 'Teacher', 
         'Q1 Good', 'Q1 Ok', 'Q1 Bad',
         'Q2 Good', 'Q2 Ok', 'Q2 Bad',
         'Q3 Good', 'Q3 Ok', 'Q3 Bad',
         'Q4 Good', 'Q4 Ok', 'Q4 Bad'],
        ...studentVoiceData
    ]);
    XLSX.utils.book_append_sheet(workbook, studentVoiceSheet, 'Student Voice');

    // Export Grade Breakdown
    const gradeBreakdownData = processGradeBreakdown(assessmentData, teacherData);
    const gradeBreakdownSheet = XLSX.utils.aoa_to_sheet([
        ['School', 'Subject', 'Grade', 'Teacher',
         'Q1 Exceeds', 'Q1 Meets', 'Q1 Approaches', 'Q1 DNM',
         'Q2 Exceeds', 'Q2 Meets', 'Q2 Approaches', 'Q2 DNM',
         'Q3 Exceeds', 'Q3 Meets', 'Q3 Approaches', 'Q3 DNM',
         'Q4 Exceeds', 'Q4 Meets', 'Q4 Approaches', 'Q4 DNM'],
        ...gradeBreakdownData
    ]);
    XLSX.utils.book_append_sheet(workbook, gradeBreakdownSheet, 'Grade Breakdown');

    // Export PLC Reports
    const plcData = processPLCReports(learningReports);
    const plcSheet = XLSX.utils.aoa_to_sheet([
        ['School', 'Quarter', 'Date', 'Title', 'Description', 'Goal', 
         'Admin Support', 'Coach Support', 'Teacher Support', 'Planned By'],
        ...plcData
    ]);
    XLSX.utils.book_append_sheet(workbook, plcSheet, 'PLC Reports');

    // Add Grade Level Teams sheet
    const teamStatsData = processTeamStats(iepData, teacherData);
    const teamStatsSheet = XLSX.utils.aoa_to_sheet([
        ['School', 'Teacher', 
         'Q1 IEP', 'Q1 Tier 2', 'Q1 Tier 3',
         'Q2 IEP', 'Q2 Tier 2', 'Q2 Tier 3',
         'Q3 IEP', 'Q3 Tier 2', 'Q3 Tier 3',
         'Q4 IEP', 'Q4 Tier 2', 'Q4 Tier 3'],
        ...teamStatsData
    ]);
    XLSX.utils.book_append_sheet(workbook, teamStatsSheet, 'Grade Level Teams');

    // Add Coaching Notes sheet
    const coachingData = processCoachingNotes(coachingNotes);
    const coachingSheet = XLSX.utils.aoa_to_sheet([
        ['School', 'Coach', 'Date', 'Note', 'Subject', 'Type'],
        ...coachingData
    ]);
    XLSX.utils.book_append_sheet(workbook, coachingSheet, 'Coaching Notes');

    // Save the file
    XLSX.writeFile(workbook, `DistrictStats_${new Date().toISOString().split('T')[0]}.xlsx`);
};

const processAssessmentCounts = (assessmentData, teacherData) => {
    const rows = [];
    
    assessmentData.forEach(assessment => {
        assessment.schools?.forEach(school => {
            school.subjects?.forEach(subject => {
                subject.grades?.forEach(grade => {
                    grade.teachers?.forEach(teacher => {
                        const teacherName = teacherData[teacher.teacherId]?.fullName || teacher.teacherId;
                        const quarterCounts = {Q1: 0, Q2: 0, Q3: 0, Q4: 0};
                        
                        // Count based on assessment end week
                        if (assessment.metadata?.endWeek) {
                            const quarter = getQuarter(assessment.metadata.endWeek);
                            quarterCounts[quarter]++;
                        }
                        
                        // Check if we already have a row for this combination
                        const existingRow = rows.find(row => 
                            row[0] === school.schoolName && 
                            row[1] === subject.name && 
                            row[2] === grade.name && 
                            row[3] === teacherName
                        );
                        
                        if (existingRow) {
                            // Add to existing counts
                            quarterCounts.Q1 && (existingRow[4] += quarterCounts.Q1);
                            quarterCounts.Q2 && (existingRow[5] += quarterCounts.Q2);
                            quarterCounts.Q3 && (existingRow[6] += quarterCounts.Q3);
                            quarterCounts.Q4 && (existingRow[7] += quarterCounts.Q4);
                        } else {
                            // Create new row
                            rows.push([
                                school.schoolName,
                                subject.name,
                                grade.name,
                                teacherName,
                                quarterCounts.Q1,
                                quarterCounts.Q2,
                                quarterCounts.Q3,
                                quarterCounts.Q4
                            ]);
                        }
                    });
                });
            });
        });
    });

    return rows;
};

const processStudentVoice = (assessmentData, teacherData) => {
    const rows = [];
    
    assessmentData.forEach(assessment => {
        assessment.schools?.forEach(school => {
            school.subjects?.forEach(subject => {
                subject.grades?.forEach(grade => {
                    grade.teachers?.forEach(teacher => {
                        const teacherName = teacherData[teacher.teacherId]?.fullName || teacher.teacherId;
                        const voiceData = teacher.stats?.studentVoices || {};
                        
                        rows.push([
                            school.schoolName,
                            subject.name,
                            grade.name,
                            teacherName,
                            voiceData.good || 0, voiceData.somewhatGood || 0, voiceData.notGood || 0,
                            voiceData.good || 0, voiceData.somewhatGood || 0, voiceData.notGood || 0,
                            voiceData.good || 0, voiceData.somewhatGood || 0, voiceData.notGood || 0,
                            voiceData.good || 0, voiceData.somewhatGood || 0, voiceData.notGood || 0
                        ]);
                    });
                });
            });
        });
    });

    return rows;
};

const processGradeBreakdown = (assessmentData, teacherData) => {
    const rows = [];
    
    assessmentData.forEach(assessment => {
        assessment.schools?.forEach(school => {
            school.subjects?.forEach(subject => {
                subject.grades?.forEach(grade => {
                    grade.teachers?.forEach(teacher => {
                        const teacherName = teacherData[teacher.teacherId]?.fullName || teacher.teacherId;
                        const gradeData = teacher.stats?.grades?.categories || {};
                        
                        rows.push([
                            school.schoolName,
                            subject.name,
                            grade.name,
                            teacherName,
                            gradeData.exceeds || 0, gradeData.meets || 0, gradeData.approaches || 0, gradeData.does_not_meet || 0,
                            gradeData.exceeds || 0, gradeData.meets || 0, gradeData.approaches || 0, gradeData.does_not_meet || 0,
                            gradeData.exceeds || 0, gradeData.meets || 0, gradeData.approaches || 0, gradeData.does_not_meet || 0,
                            gradeData.exceeds || 0, gradeData.meets || 0, gradeData.approaches || 0, gradeData.does_not_meet || 0
                        ]);
                    });
                });
            });
        });
    });

    return rows;
};

const processPLCReports = (learningReports) => {
    const rows = [];
    
    learningReports.forEach(report => {
        // Get quarter based on month instead of week
        const date = new Date(report.timeStamp);
        const month = date.getMonth(); // 0-11
        const quarter = month <= 2 ? 'Q3' : 
                       month <= 5 ? 'Q4' : 
                       month <= 8 ? 'Q1' : 'Q2';
        
        rows.push([
            report.schoolName,
            quarter,
            formatDateTime(report.timeStamp),
            report.title,
            report.description,
            report.goal,
            report.adminSupport,
            report.coachSupport,
            report.teacherSupport,
            report.plannedBy
        ]);
    });

    return rows;
};

const processTeamStats = (iepData, teacherData) => {
    const rows = [];
    const quarterBounds = {
        Q1: {
            start: new Date(2024, 7, 1),
            end: new Date(2024, 9, 31)
        },
        Q2: {
            start: new Date(2024, 10, 1),
            end: new Date(2024, 11, 31)
        },
        Q3: {
            start: new Date(2025, 0, 1),
            end: new Date(2025, 2, 31)
        },
        Q4: {
            start: new Date(2025, 3, 1),
            end: new Date(2025, 5, 30)
        }
    };

    // Create a map to collect stats
    const statsMap = new Map();

    iepData.forEach(iep => {
        if (!iep.timeStamp?.seconds) return;
        
        const timestamp = new Date(iep.timeStamp.seconds * 1000);
        let currentQuarter = null;
        
        Object.entries(quarterBounds).forEach(([quarter, bounds]) => {
            if (timestamp >= bounds.start && timestamp <= bounds.end) {
                currentQuarter = quarter;
            }
        });
        
        if (!currentQuarter) return;

        const key = `${iep.schoolId}-${iep.teacherId}`;
        if (!statsMap.has(key)) {
            statsMap.set(key, {
                schoolId: iep.schoolId,
                schoolName: iep.student?.schoolName || 'Unknown School',
                teacherId: iep.teacherId,
                teacherName: teacherData[iep.teacherId]?.fullName || 'Unknown Teacher',
                quarters: {
                    Q1: { IEP: 0, 'Tier 2': 0, 'Tier 3': 0 },
                    Q2: { IEP: 0, 'Tier 2': 0, 'Tier 3': 0 },
                    Q3: { IEP: 0, 'Tier 2': 0, 'Tier 3': 0 },
                    Q4: { IEP: 0, 'Tier 2': 0, 'Tier 3': 0 }
                }
            });
        }

        const stats = statsMap.get(key);
        const planType = iep.isMTSS ? `Tier ${iep.iep?.tier}` : 'IEP';
        stats.quarters[currentQuarter][planType]++;
    });

    // Convert map to rows
    for (const stats of statsMap.values()) {
        rows.push([
            stats.schoolName,
            stats.teacherName,
            // Q1
            stats.quarters.Q1.IEP,
            stats.quarters.Q1['Tier 2'],
            stats.quarters.Q1['Tier 3'],
            // Q2
            stats.quarters.Q2.IEP,
            stats.quarters.Q2['Tier 2'],
            stats.quarters.Q2['Tier 3'],
            // Q3
            stats.quarters.Q3.IEP,
            stats.quarters.Q3['Tier 2'],
            stats.quarters.Q3['Tier 3'],
            // Q4
            stats.quarters.Q4.IEP,
            stats.quarters.Q4['Tier 2'],
            stats.quarters.Q4['Tier 3']
        ]);
    }

    return rows;
};

const processCoachingNotes = (coachingNotes) => {
    const rows = [];
    
    coachingNotes.forEach(note => {
        rows.push([
            note.schoolName,
            note.author?.firstName + ' ' + note.author?.lastName,
            formatDateTime(note.timeStamp),
            note.text || '',
            note.subject || '',
            note.type || ''
        ]);
    });

    return rows;
};

const getQuarter = (week) => {
    if (week <= 9) return 'Q1';
    if (week <= 18) return 'Q2';
    if (week <= 27) return 'Q3';
    return 'Q4';
};
