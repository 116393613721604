import React from 'react';
import { Card, Button } from 'antd';

class ProfessionalLearningPlanView extends React.Component {
    state = {
        expandedSchools: new Set(),
        selectedYear: '2024-2025'
    };

    getQuarterBounds() {
        const year = parseInt(this.state.selectedYear.split('-')[0]);
        return {
            Q1: {
                start: new Date(year, 7, 1),
                end: new Date(year, 9, 30)
            },
            Q2: {
                start: new Date(year, 10, 1),
                end: new Date(year, 12, 31)
            },
            Q3: {
                start: new Date(year + 1, 0, 1),
                end: new Date(year + 1, 2, 31)
            },
            Q4: {
                start: new Date(year + 1, 3, 1),
                end: new Date(year + 1, 6, 31)
            }
        };
    }

    calculateStats() {
        const { learningReports } = this.props;
        const quarterBounds = this.getQuarterBounds();
        const stats = {};

        learningReports.forEach(report => {
            if (!stats[report.schoolId]) {
                stats[report.schoolId] = {
                    schoolId: report.schoolId,
                    name: report.schoolName || 'Unknown School',
                    quarters: {
                        Q1: { hasReport: false, reports: [] },
                        Q2: { hasReport: false, reports: [] },
                        Q3: { hasReport: false, reports: [] },
                        Q4: { hasReport: false, reports: [] }
                    }
                };
            }
        });

        learningReports.forEach(report => {
            const reportDate = new Date(report.timeStamp);
            Object.entries(quarterBounds).forEach(([quarter, bounds]) => {
                if (reportDate >= bounds.start && reportDate <= bounds.end) {
                    stats[report.schoolId].quarters[quarter].hasReport = true;
                    stats[report.schoolId].quarters[quarter].reports.push({
                        title: report.title,
                        date: reportDate,
                        description: report.description,
                        adminSupport: report.adminSupport,
                        coachSupport: report.coachSupport,
                        teacherSupport: report.teacherSupport,
                        goal: report.goal,
                        plannedBy: report.plannedBy
                    });
                }
            });
        });

        return Object.values(stats)
            .filter(school => school.name !== 'Unknown School')
            .sort((a, b) => a.name.localeCompare(b.name));
    }

    toggleSchool = (schoolId) => {
        this.setState(prevState => ({
            expandedSchools: new Set(
                prevState.expandedSchools.has(schoolId)
                    ? Array.from(prevState.expandedSchools).filter(id => id !== schoolId)
                    : [...prevState.expandedSchools, schoolId]
            )
        }));
    };

    expandAll = () => {
        const stats = this.calculateStats();
        this.setState({
            expandedSchools: new Set(stats.map(school => school.schoolId))
        });
    };

    collapseAll = () => {
        this.setState({
            expandedSchools: new Set()
        });
    };

    renderQuarterStatus = (hasReport) => (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '12px',
            fontSize: '32px',
            color: hasReport ? '#52c41a' : '#ff4d4f'
        }}>
            {hasReport ? '✓' : '×'}
        </div>
    );

    renderReportDetails = (reports) => {
        if (!reports || reports.length === 0) {
            return <div style={{ padding: '12px', color: '#999', fontStyle: 'italic' }}>No reports submitted</div>;
        }

        return reports.map((report, index) => (
            <div key={index} style={{
                padding: '16px',
                borderBottom: index < reports.length - 1 ? '1px solid #f0f0f0' : 'none',
                backgroundColor: 'white',
                borderRadius: '4px',
                margin: '8px 0'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    marginBottom: '8px'
                }}>
                    <div style={{
                        fontWeight: 600,
                        fontSize: '15px',
                        color: '#000'
                    }}>
                        {report.title}
                    </div>
                    <div style={{
                        color: '#8c8c8c',
                        fontSize: '13px',
                        fontWeight: 500
                    }}>
                        {new Date(report.date).toLocaleDateString()}
                    </div>
                </div>

                <div style={{
                    fontSize: '14px',
                    lineHeight: '1.6',
                    color: '#262626',
                    marginBottom: '12px'
                }}>
                    {report.description}
                </div>

                <div style={{ marginTop: '16px' }}>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                        gap: '16px',
                        fontSize: '13px'
                    }}>
                        <div>
                            <div style={{ fontWeight: 600, color: '#434343', marginBottom: '4px' }}>Goal</div>
                            <div style={{ color: '#595959' }}>{report.goal}</div>
                        </div>

                        <div>
                            <div style={{ fontWeight: 600, color: '#434343', marginBottom: '4px' }}>Support Plan</div>
                            <div style={{
                                display: 'grid',
                                gap: '8px',
                                color: '#595959'
                            }}>
                                <div>
                                    <span style={{ color: '#000' }}>Admin: </span>
                                    {report.adminSupport}
                                </div>
                                <div>
                                    <span style={{ color: '#000' }}>Coach: </span>
                                    {report.coachSupport}
                                </div>
                                <div>
                                    <span style={{ color: '#000' }}>Teacher: </span>
                                    {report.teacherSupport}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{
                        marginTop: '12px',
                        fontSize: '13px',
                        color: '#8c8c8c',
                        textAlign: 'right'
                    }}>
                        Planned by: {report.plannedBy}
                    </div>
                </div>
            </div>
        ));
    };

    render() {
        const stats = this.calculateStats();
        const { expandedSchools } = this.state;

        if (!stats.length) {
            return (
                <Card title="Professional Learning Plans">
                    <div style={{
                        padding: '48px 0',
                        textAlign: 'center',
                        background: '#fafafa',
                        borderRadius: '4px'
                    }}>
                        <div style={{
                            fontSize: '16px',
                            color: '#262626',
                            marginBottom: '8px'
                        }}>
                            No learning plans available
                        </div>
                        <div style={{
                            fontSize: '14px',
                            color: '#8c8c8c'
                        }}>
                            Learning plans for this district will appear here once submitted
                        </div>
                    </div>
                </Card>
            );
        }

        return (
            <Card
                title="Professional Learning Plans"
                extra={
                    <div>
                        <Button onClick={this.expandAll} style={{ marginRight: '8px' }}>Expand All</Button>
                        <Button onClick={this.collapseAll}>Collapse All</Button>
                    </div>
                }
            >
                <div style={{ overflowX: 'auto' }}>
                    <table style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        fontSize: '14px'
                    }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #e8e8e8' }}>
                                <th style={{
                                    textAlign: 'left',
                                    padding: '12px',
                                    backgroundColor: '#fafafa',
                                    fontWeight: 500,
                                    width: '250px'
                                }}>
                                    School
                                </th>
                                {['Q1', 'Q2', 'Q3', 'Q4'].map(quarter => (
                                    <th key={quarter} style={{
                                        padding: '12px',
                                        backgroundColor: '#fafafa',
                                        fontWeight: 500,
                                        textAlign: 'center',
                                        width: '100px'
                                    }}>
                                        {quarter}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {stats.map(school => {
                                const rows = [];
                                const isExpanded = expandedSchools.has(school.schoolId);

                                rows.push(
                                    <tr key={school.schoolId}>
                                        <td style={{ padding: '12px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span
                                                    style={{
                                                        cursor: 'pointer',
                                                        marginRight: '8px',
                                                        color: '#666'
                                                    }}
                                                    onClick={() => this.toggleSchool(school.schoolId)}
                                                >
                                                    {isExpanded ? '▼' : '►'}
                                                </span>
                                                <span style={{
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    color: '#000'
                                                }}>
                                                    {school.name}
                                                </span>
                                            </div>
                                        </td>
                                        {['Q1', 'Q2', 'Q3', 'Q4'].map(quarter => (
                                            <td key={quarter} style={{ textAlign: 'center' }}>
                                                {this.renderQuarterStatus(school.quarters[quarter].hasReport)}
                                            </td>
                                        ))}
                                    </tr>
                                );

                                if (isExpanded) {
                                    rows.push(
                                        <tr key={`${school.schoolId}-details`}>
                                            <td colSpan={5} style={{
                                                backgroundColor: '#fafafa',
                                                padding: '0'
                                            }}>
                                                {['Q1', 'Q2', 'Q3', 'Q4'].map(quarter => (
                                                    <div key={quarter} style={{
                                                        padding: '16px',
                                                        borderBottom: '1px solid #f0f0f0'
                                                    }}>
                                                        <div style={{
                                                            fontWeight: 600,
                                                            marginBottom: '12px',
                                                            color: '#262626',
                                                            fontSize: '14px'
                                                        }}>
                                                            {quarter} Reports
                                                        </div>
                                                        {this.renderReportDetails(school.quarters[quarter].reports)}
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                    );
                                }

                                return rows;
                            })}
                        </tbody>
                    </table>
                </div>
            </Card>
        );
    }
}

export default ProfessionalLearningPlanView;
