import React from 'react';
import { Card, Button, Select } from 'antd';

class CoachNoteView extends React.Component {
    state = {
        expandedSchools: new Set(),
        selectedYear: '2024-2025'
    };

    getQuarterBounds() {
        const year = parseInt(this.state.selectedYear.split('-')[0]);
        return {
            Q1: {
                start: new Date(year, 7, 1),
                end: new Date(year, 9, 30)
            },
            Q2: {
                start: new Date(year, 10, 1),
                end: new Date(year, 11, 31)
            },
            Q3: {
                start: new Date(year + 1, 0, 1),
                end: new Date(year + 1, 2, 31)
            },
            Q4: {
                start: new Date(year + 1, 3, 1),
                end: new Date(year + 1, 6, 31)
            }
        };
    }

    calculateStats() {
        const { coachingNotes } = this.props;
        const quarterBounds = this.getQuarterBounds();
        const schoolData = {};

        coachingNotes.forEach(note => {
            const noteDate = note.timeStamp;
            
            // Find which quarter this note belongs to
            let noteQuarter = null;
            Object.entries(quarterBounds).forEach(([quarter, bounds]) => {
                if (noteDate >= bounds.start && noteDate <= bounds.end) {
                    noteQuarter = quarter;
                }
            });

            // Skip if note doesn't fall within current academic year
            if (!noteQuarter) return;

            const schoolId = note.schoolId;
            const author = note.author;

            if (!schoolData[schoolId]) {
                schoolData[schoolId] = {
                    schoolId,
                    schoolName: note.schoolName || 'Unknown School',
                    counts: { Q1: 0, Q2: 0, Q3: 0, Q4: 0, total: 0 },
                    coaches: {}
                };
            }

            // Update school total counts
            schoolData[schoolId].counts[noteQuarter]++;
            schoolData[schoolId].counts.total++;

            // Update individual coach counts
            if (author) {
                const coachId = author.authorId;
                if (!schoolData[schoolId].coaches[coachId]) {
                    schoolData[schoolId].coaches[coachId] = {
                        id: coachId,
                        firstName: author.firstName || '',
                        lastName: author.lastName || '',
                        counts: { Q1: 0, Q2: 0, Q3: 0, Q4: 0, total: 0 }
                    };
                }
                schoolData[schoolId].coaches[coachId].counts[noteQuarter]++;
                schoolData[schoolId].coaches[coachId].counts.total++;
            }
        });

        return Object.values(schoolData).sort((a, b) => 
            a.schoolName.localeCompare(b.schoolName)
        );
    }

    toggleSchool = (schoolId) => {
        this.setState(prevState => ({
            expandedSchools: new Set(
                prevState.expandedSchools.has(schoolId)
                    ? Array.from(prevState.expandedSchools).filter(id => id !== schoolId)
                    : [...prevState.expandedSchools, schoolId]
            )
        }));
    };

    expandAll = () => {
        const stats = this.calculateStats();
        this.setState({
            expandedSchools: new Set(stats.map(school => school.schoolId))
        });
    };

    collapseAll = () => {
        this.setState({
            expandedSchools: new Set()
        });
    };

    renderCountCell = (count) => {
        return (
            <td style={{ 
                padding: '12px',
                textAlign: 'center',
                minWidth: '100px',
                fontFamily: 'monospace',
                fontSize: '0.85rem'
            }}>
                {count || '-'}
            </td>
        );
    };

      renderRow = (data, level, schoolId = null) => {
        const isSchool = level === 'school';
        const isExpanded = isSchool && this.state.expandedSchools.has(data.schoolId);
        const indent = isSchool ? 0 : 20;

        return (
            <tr key={isSchool ? data.schoolId : data.id} style={{ 
                backgroundColor: isSchool ? 'white' : '#fafafa'
            }}>
                <td style={{ 
                    padding: '12px',
                    width: '250px',
                    whiteSpace: 'nowrap'
                }}>
                    <div style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        marginLeft: `${indent}px`
                    }}>
                        {isSchool && (
                            <span 
                                style={{ 
                                    cursor: 'pointer', 
                                    marginRight: '8px',
                                    color: '#666'
                                }}
                                onClick={() => this.toggleSchool(data.schoolId)}
                            >
                                {isExpanded ? '▼' : '►'}
                            </span>
                        )}
                        <span style={{ 
                            fontWeight: isSchool ? '500' : '400',
                            color: isSchool ? '#000' : '#666'
                        }}>
                            {isSchool ? data.schoolName : `${data.firstName} ${data.lastName}`}
                        </span>
                    </div>
                </td>
                {['Q1', 'Q2', 'Q3', 'Q4'].map(quarter => 
                    this.renderCountCell(data.counts[quarter])
                )}
            </tr>
        );
    };

    render() {
        const stats = this.calculateStats();

        return (
            <Card 
                title="Coaching Notes"
                extra={
                    <div>
                        <Button onClick={this.expandAll} style={{ marginRight: '8px' }}>
                            Expand All
                        </Button>
                        <Button onClick={this.collapseAll}>
                            Collapse All
                        </Button>
                    </div>
                }
            >
                <div style={{ overflowX: 'auto' }}>
                    <table style={{ 
                        width: '100%', 
                        borderCollapse: 'collapse',
                        fontSize: '14px'
                    }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #e8e8e8' }}>
                                <th style={{ 
                                    textAlign: 'left', 
                                    padding: '12px',
                                    backgroundColor: '#fafafa',
                                    fontWeight: 500,
                                    width: '250px'
                                }}>
                                    School / Coach
                                </th>
                                {['Q1', 'Q2', 'Q3', 'Q4'].map(quarter => (
                                    <th key={quarter} style={{ 
                                        padding: '12px',
                                        backgroundColor: '#fafafa',
                                        fontWeight: 500,
                                        textAlign: 'center'
                                    }}>
                                        {quarter}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {stats.map(school => {
                                const rows = [this.renderRow(school, 'school')];
                                
                                if (this.state.expandedSchools.has(school.schoolId)) {
                                    Object.values(school.coaches)
                                        .sort((a, b) => a.lastName.localeCompare(b.lastName))
                                        .forEach(coach => {
                                            rows.push(this.renderRow(coach, 'coach', school.schoolId));
                                        });
                                }
                                
                                return rows;
                            })}
                        </tbody>
                    </table>
                </div>
            </Card>
        );
    }
}

export default CoachNoteView;
