import React from 'react';
import { Card, Button } from 'antd';
import { gradeNameSort } from '../Util';

const ResponseIcons = {
    good: '😀',
    somewhatGood: '😐',
    notGood: '☹️'
};

const ResponseColors = {
    good: '#389e0d',
    somewhatGood: '#7b6b00',
    notGood: '#820014'
};

class StudentVoiceView extends React.Component {
    state = {
        expandedSchools: new Set(),
        expandedSubjects: new Set(),
        expandedGrades: new Set(),
        selectedCategory: 'all'
    };

    calculateStats() {
        const { assessmentData } = this.props;
        const { selectedCategory } = this.state;
        const stats = {};

        const filteredAssessments = assessmentData.filter(assessment =>
            selectedCategory === 'all' || assessment.metadata?.assessmentCategory === selectedCategory
        );

        filteredAssessments.forEach(assessment => {
            const quarter = this.getQuarter(assessment.metadata?.endWeek);
            if (!quarter) return;

            assessment.schools?.forEach(school => {
                if (!stats[school.schoolId]) {
                    stats[school.schoolId] = {
                        name: school.schoolName || 'Unknown School',
                        subjects: {},
                        quarterlyResponses: {
                            Q1: { notGood: 0, somewhatGood: 0, good: 0 },
                            Q2: { notGood: 0, somewhatGood: 0, good: 0 },
                            Q3: { notGood: 0, somewhatGood: 0, good: 0 },
                            Q4: { notGood: 0, somewhatGood: 0, good: 0 }
                        }
                    };
                }

                school.subjects?.forEach(subject => {
                    if (!stats[school.schoolId].subjects[subject.name]) {
                        stats[school.schoolId].subjects[subject.name] = {
                            name: subject.name,
                            grades: {},
                            quarterlyResponses: {
                                Q1: { notGood: 0, somewhatGood: 0, good: 0 },
                                Q2: { notGood: 0, somewhatGood: 0, good: 0 },
                                Q3: { notGood: 0, somewhatGood: 0, good: 0 },
                                Q4: { notGood: 0, somewhatGood: 0, good: 0 }
                            }
                        };
                    }

                    subject.grades?.forEach(grade => {
                        const gradeKey = grade.name;
                        if (!stats[school.schoolId].subjects[subject.name].grades[gradeKey]) {
                            stats[school.schoolId].subjects[subject.name].grades[gradeKey] = {
                                name: grade.name,
                                teachers: {},
                                quarterlyResponses: {
                                    Q1: { notGood: 0, somewhatGood: 0, good: 0 },
                                    Q2: { notGood: 0, somewhatGood: 0, good: 0 },
                                    Q3: { notGood: 0, somewhatGood: 0, good: 0 },
                                    Q4: { notGood: 0, somewhatGood: 0, good: 0 }
                                }
                            };
                        }

                        const gradeStats = stats[school.schoolId].subjects[subject.name].grades[gradeKey];

                        grade.teachers?.forEach(teacher => {
                            if (!gradeStats.teachers[teacher.teacherId]) {
                                gradeStats.teachers[teacher.teacherId] = {
                                    teacherId: teacher.teacherId,
                                    quarterlyResponses: {
                                        Q1: { notGood: 0, somewhatGood: 0, good: 0 },
                                        Q2: { notGood: 0, somewhatGood: 0, good: 0 },
                                        Q3: { notGood: 0, somewhatGood: 0, good: 0 },
                                        Q4: { notGood: 0, somewhatGood: 0, good: 0 }
                                    }
                                };
                            }

                            const voices = teacher.stats?.studentVoices || {};
                            const teacherCurrentResponses = gradeStats.teachers[teacher.teacherId].quarterlyResponses[quarter];

                            ['notGood', 'somewhatGood', 'good'].forEach(type => {
                                const count = voices[type] || 0;
                                const newCount = (teacherCurrentResponses[type] || 0) + count;
                                gradeStats.teachers[teacher.teacherId].quarterlyResponses[quarter][type] = newCount;
                                gradeStats.quarterlyResponses[quarter][type] += count;
                                stats[school.schoolId].subjects[subject.name].quarterlyResponses[quarter][type] += count;
                                stats[school.schoolId].quarterlyResponses[quarter][type] += count;
                            });
                        });
                    });
                });
            });
        });

        return Object.values(stats).sort((a, b) => a.name.localeCompare(b.name));
    }

    handleCategoryChange = (category) => {
        this.setState({ selectedCategory: category });
    };

    getQuarter(week) {
        if (week <= 9) return 'Q1';
        if (week <= 18) return 'Q2';
        if (week <= 27) return 'Q3';
        return 'Q4';
    }

    toggleSchool = (schoolName) => {
        this.setState(prevState => ({
            expandedSchools: new Set(
                prevState.expandedSchools.has(schoolName)
                    ? Array.from(prevState.expandedSchools).filter(name => name !== schoolName)
                    : [...prevState.expandedSchools, schoolName]
            )
        }));
    };

    toggleSubject = (key) => {
        this.setState(prevState => ({
            expandedSubjects: new Set(
                prevState.expandedSubjects.has(key)
                    ? Array.from(prevState.expandedSubjects).filter(k => k !== key)
                    : [...prevState.expandedSubjects, key]
            )
        }));
    };

    toggleGrade = (key) => {
        this.setState(prevState => ({
            expandedGrades: new Set(
                prevState.expandedGrades.has(key)
                    ? Array.from(prevState.expandedGrades).filter(k => k !== key)
                    : [...prevState.expandedGrades, key]
            )
        }));
    };

    expandAll = () => {
        const stats = this.calculateStats();
        const allSchools = new Set();
        const allSubjects = new Set();
        const allGrades = new Set();

        stats.forEach(school => {
            allSchools.add(school.name);
            Object.entries(school.subjects).forEach(([subject, subjectData]) => {
                const subjectKey = `${school.name}-${subject}`;
                allSubjects.add(subjectKey);

                Object.keys(subjectData.grades).forEach(grade => {
                    allGrades.add(`${subjectKey}-${grade}`);
                });
            });
        });

        this.setState({
            expandedSchools: allSchools,
            expandedSubjects: allSubjects,
            expandedGrades: allGrades
        });
    };

    collapseAll = () => {
        this.setState({
            expandedSchools: new Set(),
            expandedSubjects: new Set(),
            expandedGrades: new Set()
        });
    };


    renderResponseCell = (responses, quarter) => {
        if (!responses.notGood && !responses.somewhatGood && !responses.good) {
            return (
                <>
                    <td className="response-cell quarter-start">-</td>
                    <td className="response-cell">-</td>
                    <td className="response-cell">-</td>
                </>
            );
        }

        const formatCount = (count) => count.toString().padStart(3);

        return (
            <>
                <td className="response-cell quarter-start" style={{ color: responses.good ? ResponseColors.good : '#00000040' }}>
                    {formatCount(responses.good)}
                </td>
                <td className="response-cell" style={{ color: responses.somewhatGood ? ResponseColors.somewhatGood : '#00000040' }}>
                    {formatCount(responses.somewhatGood)}
                </td>
                <td className="response-cell" style={{ color: responses.notGood ? ResponseColors.notGood : '#00000040' }}>
                    {formatCount(responses.notGood)}
                </td>
            </>
        );
    };


    renderHierarchyRow = (item, level, key, hasChildren = false) => {
        const { teacherData } = this.props;
        const isExpanded = level === 'school' ? this.state.expandedSchools.has(item.name) :
            level === 'subject' ? this.state.expandedSubjects.has(key) :
                level === 'grade' ? this.state.expandedGrades.has(key) : false;

        const toggleExpand = level === 'school' ? () => this.toggleSchool(item.name) :
            level === 'subject' ? () => this.toggleSubject(key) :
                level === 'grade' ? () => this.toggleGrade(key) : null;

        const indent = level === 'subject' ? 20 : level === 'grade' ? 40 : level === 'teacher' ? 60 : 0;
        const displayName = level === 'teacher' ? teacherData[item.teacherId]?.fullName : item.name;

        return (
            <tr key={key} style={{ backgroundColor: level === 'teacher' ? '#fafafa' : 'white' }}>
                <td style={{
                    padding: '12px',
                    width: '250px',
                    whiteSpace: 'nowrap'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: `${indent}px`
                    }}>
                        {hasChildren && (
                            <span
                                style={{ cursor: 'pointer', marginRight: '8px', color: '#666' }}
                                onClick={toggleExpand}
                            >
                                {isExpanded ? '▼' : '►'}
                            </span>
                        )}
                        <span style={{
                            fontWeight: level === 'school' ? '500' : '400',
                            color: level === 'teacher' ? '#666' : '#000'
                        }}>
                            {displayName}
                        </span>
                    </div>
                </td>
                {['Q1', 'Q2', 'Q3', 'Q4'].map((quarter) =>
                    this.renderResponseCell(item.quarterlyResponses[quarter], quarter)
                )}
            </tr>
        );
    };

    render() {
        const stats = this.calculateStats();
        const { selectedCategory } = this.state;

        return (
            <Card
                title="Student Voice"
                extra={
                    <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                        <div style={{
                            display: 'flex',
                            gap: '8px',
                            borderRight: '1px solid #e8e8e8',
                            paddingRight: '16px'
                        }}>
                            {[
                                { value: 'all', label: 'All' },
                                { value: 'common_assessment', label: 'Common Assessments' },
                                { value: 'benchmark', label: 'Benchmarks' }
                            ].map(option => (
                                <Button
                                    key={option.value}
                                    onClick={() => this.handleCategoryChange(option.value)}
                                    type={selectedCategory === option.value ? 'primary' : 'default'}
                                >
                                    {option.label}
                                </Button>
                            ))}
                        </div>
                        <div>
                            <Button onClick={this.expandAll} style={{ marginRight: '8px' }}>Expand All</Button>
                            <Button onClick={this.collapseAll}>Collapse All</Button>
                        </div>
                    </div>
                }
            >
                <div style={{ overflowX: 'auto' }}>
                    <table style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        fontSize: '14px'
                    }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #e8e8e8' }}>
                                <th rowSpan="2" style={{
                                    textAlign: 'left',
                                    padding: '12px',
                                    backgroundColor: '#fafafa',
                                    fontWeight: 500
                                }}>
                                    Name
                                </th>
                                {['Q1', 'Q2', 'Q3', 'Q4'].map(quarter => (
                                    <th key={quarter} colSpan="3" style={{
                                        padding: '8px',
                                        backgroundColor: '#fafafa',
                                        fontWeight: 500,
                                        textAlign: 'center'
                                    }}>
                                        {quarter}
                                    </th>
                                ))}
                            </tr>
                            <tr style={{ borderBottom: '1px solid #e8e8e8' }}>
                                {Array(4).fill().map((_, i) => (
                                    <React.Fragment key={i}>
                                        <th className="response-header">😀 Good</th>
                                        <th className="response-header">😐 Ok</th>
                                        <th className="response-header">☹️ Bad</th>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {stats.map(school => {
                                const rows = [];
                                rows.push(this.renderHierarchyRow(school, 'school', school.name, true));

                                if (this.state.expandedSchools.has(school.name)) {
                                    Object.entries(school.subjects).forEach(([subjectName, subject]) => {
                                        const subjectKey = `${school.name}-${subjectName}`;
                                        rows.push(this.renderHierarchyRow(subject, 'subject', subjectKey, true));

                                        if (this.state.expandedSubjects.has(subjectKey)) {
                                            Object.keys(subject.grades).sort(gradeNameSort).forEach(gradeName => {
                                                let grade = subject.grades[gradeName];
                                                const gradeKey = `${subjectKey}-${gradeName}`;
                                                rows.push(this.renderHierarchyRow(grade, 'grade', gradeKey, true));

                                                if (this.state.expandedGrades.has(gradeKey)) {
                                                    Object.keys(grade.teachers).sort((a, b) => {
                                                        const teacherA = this.props.teacherData[a];
                                                        const teacherB = this.props.teacherData[b];
                                                        return teacherA?.lastName.localeCompare(teacherB?.lastName);
                                                    }).forEach(teacherId => {
                                                        let teacher = grade.teachers[teacherId];
                                                        rows.push(this.renderHierarchyRow(
                                                            teacher,
                                                            'teacher',
                                                            `${gradeKey}-${teacherId}`,
                                                            false
                                                        ));
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }

                                return rows;
                            })}
                        </tbody>
                    </table>
                </div>
                <style>{`
                    .response-cell {
                        padding: 12px;
                        text-align: center;
                        min-width: 50px;
                        font-family: monospace;
                        font-size: 0.85rem;
                    }
                    .response-cell.quarter-start {
                        border-left: 2px solid #e8e8e8;
                    }

                    .response-header {
                        padding: 8px;
                        background-color: #fafafa;
                        font-weight: normal;
                        text-align: center;
                        font-size: 0.85rem;
                    }
                `}</style>
            </Card>
        );
    }
}

export default StudentVoiceView;
