import React from 'react';
import { Card, Button } from 'antd';

class GradeLevelTeamsView extends React.Component {
    state = {
        expandedSchools: new Set()
    };

    getQuarterBounds() {
        return {
            Q1: {
                start: new Date(2024, 7, 1),    // August 1st
                end: new Date(2024, 9, 31)      // October 31st
            },
            Q2: {
                start: new Date(2024, 10, 1),   // November 1st
                end: new Date(2024, 12, 31)     // December 31st
            },
            Q3: {
                start: new Date(2025, 0, 1),    // January 1st
                end: new Date(2025, 2, 31)      // March 31st
            },
            Q4: {
                start: new Date(2025, 3, 1),    // April 1st
                end: new Date(2025, 5, 30)      // June 30th
            }
        };
    }

    calculateStats() {
        const { teacherData, iepData, assessmentData } = this.props;
        const quarterBounds = this.getQuarterBounds();
        const stats = {};

        // First, initialize the structure using schools from assessment data
        assessmentData.forEach(assessment => {
            assessment.schools?.forEach(school => {
                if (!stats[school.schoolId]) {
                    stats[school.schoolId] = {
                        schoolId: school.schoolId,
                        name: school.schoolName || 'Unknown School',
                        teachers: {},
                        quarterlyStats: {
                            Q1: { IEP: 0, 'Tier 2': 0, 'Tier 3': 0 },
                            Q2: { IEP: 0, 'Tier 2': 0, 'Tier 3': 0 },
                            Q3: { IEP: 0, 'Tier 2': 0, 'Tier 3': 0 },
                            Q4: { IEP: 0, 'Tier 2': 0, 'Tier 3': 0 }
                        }
                    };
                }
            });
        });

        // Add teachers to their respective schools
        Object.entries(teacherData).forEach(([teacherId, teacher]) => {
            const schoolId = teacher.schoolId;
            if (stats[schoolId]) {
                stats[schoolId].teachers[teacherId] = {
                    teacherId,
                    quarterlyStats: {
                        Q1: { IEP: 0, 'Tier 2': 0, 'Tier 3': 0 },
                        Q2: { IEP: 0, 'Tier 2': 0, 'Tier 3': 0 },
                        Q3: { IEP: 0, 'Tier 2': 0, 'Tier 3': 0 },
                        Q4: { IEP: 0, 'Tier 2': 0, 'Tier 3': 0 }
                    }
                };
            }
        });

        // Process IEP/MTSS data
        iepData.forEach(iep => {
            if (!iep.timeStamp?.seconds) return;

            const timestamp = new Date(iep.timeStamp.seconds * 1000);
            let currentQuarter = null;

            Object.entries(quarterBounds).forEach(([quarter, bounds]) => {
                if (timestamp >= bounds.start && timestamp <= bounds.end) {
                    currentQuarter = quarter;
                }
            });

            if (!currentQuarter) return;

            const schoolId = iep.schoolId;
            const teacherId = iep.teacherId;
            if (!stats[schoolId]) return;

            const planType = iep.isMTSS ? iep.iep?.tier : 'IEP';

            // Update school total
            stats[schoolId].quarterlyStats[currentQuarter][planType]++;

            // Update teacher stats if we have the teacher
            if (teacherId && stats[schoolId].teachers[teacherId]) {
                stats[schoolId].teachers[teacherId].quarterlyStats[currentQuarter][planType]++;
            }
        });

        return Object.values(stats).sort((a, b) => a.name.localeCompare(b.name));
    }

    toggleSchool = (schoolId) => {
        this.setState(prevState => ({
            expandedSchools: new Set(
                prevState.expandedSchools.has(schoolId)
                    ? Array.from(prevState.expandedSchools).filter(id => id !== schoolId)
                    : [...prevState.expandedSchools, schoolId]
            )
        }));
    };

    expandAll = () => {
        const stats = this.calculateStats();
        this.setState({
            expandedSchools: new Set(stats.map(school => school.schoolId))
        });
    };

    collapseAll = () => {
        this.setState({
            expandedSchools: new Set()
        });
    };

    renderStatsCell = (stats, quarter) => {
        const formatCount = (count) => count.toString().padStart(3);

        return (
            <>
                <td className="stats-cell quarter-start">
                    {formatCount(stats.IEP)}
                </td>
                <td className="stats-cell">
                    {formatCount(stats['Tier 2'])}
                </td>
                <td className="stats-cell">
                    {formatCount(stats['Tier 3'])}
                </td>
            </>
        );
    };

    renderRow = (item, level) => {
        const { teacherData } = this.props;
        const isSchool = level === 'school';
        const isExpanded = isSchool && this.state.expandedSchools.has(item.schoolId);

        return (
            <tr style={{ backgroundColor: isSchool ? 'white' : '#fafafa' }}>
                <td style={{
                    padding: '12px',
                    width: '250px',
                    whiteSpace: 'nowrap'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: isSchool ? 0 : 20
                    }}>
                        {isSchool && (
                            <span
                                style={{ cursor: 'pointer', marginRight: '8px', color: '#666' }}
                                onClick={() => this.toggleSchool(item.schoolId)}
                            >
                                {isExpanded ? '▼' : '►'}
                            </span>
                        )}
                        <span style={{
                            fontWeight: isSchool ? '500' : '400',
                            color: isSchool ? '#000' : '#666'
                        }}>
                            {isSchool ? item.name : teacherData[item.teacherId]?.fullName}
                        </span>
                    </div>
                </td>
                {['Q1', 'Q2', 'Q3', 'Q4'].map(quarter =>
                    this.renderStatsCell(item.quarterlyStats[quarter], quarter)
                )}
            </tr>
        );
    };

    render() {
        const stats = this.calculateStats();

        return (
            <Card
                title="MTSS/SPED"
                extra={
                    <div>
                        <Button onClick={this.expandAll} style={{ marginRight: '8px' }}>Expand All</Button>
                        <Button onClick={this.collapseAll}>Collapse All</Button>
                    </div>
                }
            >
                <div style={{ overflowX: 'auto' }}>
                    <table style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        fontSize: '14px'
                    }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #e8e8e8' }}>
                                <th rowSpan="2" style={{
                                    textAlign: 'left',
                                    padding: '12px',
                                    backgroundColor: '#fafafa',
                                    fontWeight: 500
                                }}>
                                    Name
                                </th>
                                {['Q1', 'Q2', 'Q3', 'Q4'].map(quarter => (
                                    <th key={quarter} colSpan="3" style={{
                                        padding: '8px',
                                        backgroundColor: '#fafafa',
                                        fontWeight: 500,
                                        textAlign: 'center'
                                    }}>
                                        {quarter}
                                    </th>
                                ))}
                            </tr>
                            <tr style={{ borderBottom: '1px solid #e8e8e8' }}>
                                {Array(4).fill().map((_, i) => (
                                    <React.Fragment key={i}>
                                        <th className="stats-header">IEP</th>
                                        <th className="stats-header">Tier 2</th>
                                        <th className="stats-header">Tier 3</th>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {stats.map(school => {
                                const rows = [this.renderRow(school, 'school')];

                                if (this.state.expandedSchools.has(school.schoolId)) {
                                    Object.entries(school.teachers)
                                        .sort((a, b) => {
                                            const teacherA = this.props.teacherData[a[0]];
                                            const teacherB = this.props.teacherData[b[0]];
                                            return teacherA?.lastName.localeCompare(teacherB?.lastName);
                                        })
                                        .forEach(([teacherId, teacher]) => {
                                            rows.push(this.renderRow(teacher, 'teacher'));
                                        });
                                }

                                return rows;
                            })}
                        </tbody>
                    </table>
                </div>
                <style>{`
                    .stats-cell {
                        padding: 12px;
                        text-align: center;
                        min-width: 50px;
                        font-family: monospace;
                        font-size: 0.85rem;
                    }
                    .stats-cell.quarter-start {
                        border-left: 2px solid #e8e8e8;
                    }
                    .stats-header {
                        padding: 8px;
                        background-color: #fafafa;
                        font-weight: normal;
                        text-align: center;
                        font-size: 0.85rem;
                    }
                    .stats-header.quarter-start {
                        border-left: 2px solid #e8e8e8;
                    }
                `}</style>
            </Card>
        );
    }
}

export default GradeLevelTeamsView;
