import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../styles/GlobalStyle.css'
import CustomFooter from '../login/CustomFooter'
import ColType from '../Types'
import { db } from '../firebase/Firebase'
import { flattenDoc, FOLDER_CURRENT, getQueryStringParam } from '../Util'
import { Layout, Row, Col, Select, Icon, Tabs, Menu } from 'antd'
import PersonAvatar from '../customcomponents/PersonAvatar'
import DistrictStatsV2 from './DistrictStatsV2'
import Scheduler from '../teacher/scheduler/Scheduler'
import StudentSummary from './StudentSummary'
import WeeklyPlanner from '../teacher/pacingguide/WeeklyPlanner'
import PacingGuideTeacherAssessmentsBase from '../teacher/pacingguide/PacingGuideTeacherAssessmentsBase'
import CoachingNotes from '../instructionalfacilitator/CoachingNotes'
const { Content } = Layout
const { Sider } = Layout
const moment = require('moment')

class AdminTeacherView extends Component {
    state = {
        schoolId: '',
        teacherId: '',
        teacher: '',
        students: null,
        studentDict: null,
        IEPGoals: null,
        IEPDict: null,
        calendarEvents: null,
        calendarDict: null,
        events: null,
        loadedData: false,
        readOnly: true,
        currentTeacherTab: 'teachers',
        menuKey: 'Students',
    }

    // Do fetch here
    componentDidMount() {
        this.getData()
    }

    componentWillReceiveProps(props, newProps) {
        this.getData()
    }

    getAdminOrInstructionalFacilitator = () => {
        if (this.props.adminBaseProps) {
            if (this.props.adminBaseProps.admin) {
                return this.props.adminBaseProps.admin
            } else if (this.props.adminBaseProps.instructionalFacilitator) {
                return this.props.adminBaseProps.instructionalFacilitator
            }
        }
        return null
    }

    getData() {
        if (this.state.loadedData) {
            return
        }

        let schoolId = getQueryStringParam('s')
        var teacherId = getQueryStringParam('t')

        this.setState({
            schoolId: schoolId,
            teacherId: teacherId,
        })

        if (teacherId && this.getAdminOrInstructionalFacilitator()) {
            this.setState({
                loadedData: true,
            })

            db.collection(ColType.teacher)
                .doc(teacherId)
                .get()
                .then((doc) => {
                    var teacher = flattenDoc(doc)

                    this.setState({
                        teacher: teacher,
                    })

                    db.collection(ColType.iep)
                        .where('teacherId', '==', teacherId)
                        .where('schoolId', '==', teacher.schoolId)
                        .where('districtId', '==', teacher.districtId)
                        .orderBy('timeStamp', 'desc')
                        .get()
                        .then((querySnapshot) => {
                            var IEPGoals = []
                            var IEPDict = {}

                            querySnapshot.forEach((doc) => {
                                var iep = flattenDoc(doc)
                                IEPGoals.push(iep)
                                IEPDict[iep.id] = iep
                            })

                            this.setState({
                                IEPGoals: IEPGoals,
                                IEPDict: IEPDict,
                            })
                        })

                    db.collection(ColType.calendarEvents)
                        .where('teacherId', '==', teacherId)
                        .where('schoolId', '==', teacher.schoolId)
                        .where('districtId', '==', teacher.districtId)
                        .orderBy('startTime')
                        .orderBy('index')
                        .orderBy('duration', 'desc')
                        .get()
                        .then((querySnapshot) => {
                            var calendarEvents = []
                            var calendarDict = {}
                            var events = []
                            var idx = 0

                            querySnapshot.forEach((doc) => {
                                if (!doc.exists) return
                                var event = flattenDoc(doc)
                                // store the doc for deletes
                                event.doc = doc
                                calendarEvents.push(event)
                                var start = moment.utc(event.startTime.seconds * 1000)
                                var end = new Date(event.endTime)
                                //start = new Date(2015, 5, parseInt(event.day, 10),
                                //  start.getHours(), start.getMinutes(), 0)
                                end = new Date(2015, 5, parseInt(event.day, 10),
                                    end.getHours(), end.getMinutes(), 0)
                                event.start = start
                                event.end = end

                                events.push(event)
                                calendarDict[idx.toString()] = {
                                    'flattenedDoc': event,
                                    'unFlattenedDoc': doc.data()
                                }
                            })

                            this.setState({
                                calendarEvents: calendarEvents,
                                calendarDict: calendarDict,
                                events: events,
                            })
                        })

                    db.collection(ColType.student)
                        .where('teacherIds', 'array-contains', teacher.id)
                        .where('schoolId', '==', teacher.schoolId)
                        .where('districtId', '==', teacher.districtId)
                        //.where('folder', '==', FOLDER_CURRENT)
                        .get()
                        .then((querySnapshot) => {
                            var students = []
                            var studentDict = {}

                            querySnapshot.forEach((doc) => {
                                var student = flattenDoc(doc)
                                students.push(student)
                                studentDict[student.id] = student
                            })

                            students.sort((a, b) => {
                                var levelDifference = 0
                                if (a.grade === 'K' && b.grade === 'K') {
                                    if (levelDifference !== 0) return levelDifference
                                    if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                                        return 1
                                    } else {
                                        return -1
                                    }
                                }
                                if (a.grade === 'K' && b.grade !== 'K') return -1
                                if (a.grade !== 'K' && b.grade === 'K') return 1
                                if (a.grade === b.grade) {
                                    if (levelDifference !== 0) return levelDifference
                                    if (a.lastName.charAt(0) > b.lastName.charAt(0)) {
                                        return 1
                                    } else {
                                        return -1
                                    }
                                }
                                var aGrade = parseInt(a.grade, 10)
                                var bGrade = parseInt(b.grade, 10)
                                if (aGrade > bGrade) return 1
                                if (bGrade > aGrade) return -1
                                return 0
                            })

                            this.setState({
                                students: students,
                                studentDict: studentDict,
                            })
                        })
                })
        }
    }

    render() {
        let adminHomeUrl = '/admin/admin-home/'
        let adminSchoolUrl = '/admin/school/'
        let adminStudentUrl = '/admin/student/'
        if (this.props.adminBaseProps &&
            this.props.adminBaseProps.instructionalFacilitator) {
            let schoolIdx = 0
            adminHomeUrl = '/instructional-facilitator/instructional-facilitator-home/'
            let qs = ''
            if (this.state.schoolId && this.props.adminBaseProps.schools) {
                schoolIdx = this.props.adminBaseProps.schools.findIndex((school) => {
                    return school.id === this.state.schoolId
                })
            }
            if (this.state.schoolId && schoolIdx > 0) {
                adminHomeUrl = '/instructional-facilitator/school/'
                qs = '?s=' + this.state.schoolId
            }

            adminHomeUrl = '/instructional-facilitator/instructional-facilitator-home/'
            adminSchoolUrl = adminHomeUrl
            adminStudentUrl = '/instructional-facilitator/student/'
        }

        return (
            <div>
                <div>
                    <Layout>
                        <Layout>
                            <Content className='layout-header-mt overflow-visible-important' style={{ marginTop: '85px' }}>
                                <Sider
                                    width={270}
                                    style={{
                                        overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 83px)',
                                        position: 'fixed',
                                        left: 0, borderRight: '1px solid #f1f1f1',
                                        background: '#fff',
                                        zIndex: 10,
                                    }}>
                                    <div style={{ marginTop: 16 }}>
                                    </div>

                                    <Menu selectedKeys={this.state.menuKey} onSelect={(e) => {
                                        this.setState({
                                            menuKey: e.key
                                        })
                                    }}>
                                        <Menu.Item key={'Students'}>
                                            <div className='font-20'>
                                                Students
                                            </div>
                                        </Menu.Item>
                                        {/*
                                        <Menu.Item key={'Assessments'}>
                                            <div className='font-16'>
                                                Assessments
                                            </div>
                                        </Menu.Item>
                                        */}
                                        {/*
                                        <Menu.Item key={'Assessment Data'}>
                                            <div className='font-16'>
                                                Assessment Data
                                            </div>
                                        </Menu.Item>
                                        */}
                                        {/*<Menu.Item key={'Weekly Planner'}>
                                            <div className='font-20'>
                                                Weekly Planner
                                            </div>
                                        </Menu.Item>*/}


                                        <Menu.Item key={'Coaching'}>
                                            <div className='font-20'>
                                                Coaching Reports
                                            </div>
                                        </Menu.Item>
                                    </Menu>
                                </Sider>
                                <Content style={{ marginLeft: 270, marginTop: "24px", overflowX: 'initial' }}>
                                    <div className='pl-4 pr-4'>
                                        <div>
                                            {this.state.schoolId &&
                                                this.state.teacher &&
                                                this.getAdminOrInstructionalFacilitator() &&
                                                this.props.adminBaseProps.district &&
                                                this.props.adminBaseProps.schoolsDict &&
                                                this.props.adminBaseProps.schoolsDict.hasOwnProperty(this.state.schoolId)
                                                ?
                                                <div>
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <div className="w-100 flex flex-v-center mb-2 font-16 font-bold">
                                                                    <Link to={adminHomeUrl + this.getAdminOrInstructionalFacilitator().id}>
                                                                        {this.props.adminBaseProps.district.district}
                                                                    </Link>
                                                                    <Icon type="right" className="ml-1 mr-1 font-14" />
                                                                    <Link to={adminSchoolUrl + this.getAdminOrInstructionalFacilitator().id + '?s=' + this.state.schoolId}>
                                                                        {this.props.adminBaseProps.schoolsDict.hasOwnProperty(this.state.schoolId) ?
                                                                            this.props.adminBaseProps.schoolsDict[this.state.schoolId].schoolName : ''}
                                                                    </Link>
                                                                    <Icon type="right" className="ml-1 mr-1 font-14" />
                                                                    <span>
                                                                        {this.state.teacher.firstName + ' ' + this.state.teacher.lastName}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {/*
                                                                <div className='relative'>
                                                                    <img src="/teacher-background.png" alt="background-img" className="w-100 object-fit-cover absolute-tl br-20 w-100" />
                                                                </div>
                                                                           
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <div className="font-bold mb-50 pb-2 font-40 flex font-fff ml-4"
                                                                            style={{ marginTop: '150px' }}>
                                                                            {this.state.teacher.firstName + ' ' + this.state.teacher.lastName}
                                                                        </div>
                                                                    </Col>
                                                                </Row> */}

                                                            <div className='br-20 overflow-hidden mb-3 mt-2'>
                                                                <div className='br-4 relative pl-3 pr-3 pt-3 relative'
                                                                    style={{ height: '220px', }}>

                                                                    <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#333' }}>
                                                                    </div>
                                                                    <div className="absolute-tl w-100 h-100 image-box-mask"
                                                                        style={{ backgroundImage: 'url(/teacher-banner.png)' }}
                                                                    >
                                                                    </div>
                                                                    <div className='absolute-tl w-100 h-100'>
                                                                        <div className='h-100'>
                                                                            <div className='flex flex-v-center font-40 font-fff w-100 h-100 p-4'>
                                                                                <div className='w-100'>
                                                                                    <div className='font-40 font-bold font-fff'>
                                                                                        {this.state.teacher.firstName + ' ' + this.state.teacher.lastName}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {this.state.menuKey === 'Weekly Planner' ?
                                                            <div>
                                                                <div className='mb-2'>
                                                                    <div className='font-24 mb-2 font-bold'>Weekly Planner</div>
                                                                </div>
                                                                <WeeklyPlanner
                                                                    pacingGuideBaseProps={{}}
                                                                    teacherBaseProps={{
                                                                        teacher: this.state.teacher,
                                                                        district: this.props.adminBaseProps.district,
                                                                    }}
                                                                    isAdmin={true}
                                                                />
                                                            </div>
                                                            : ''}

                                                        {this.state.menuKey === 'Coaching' &&
                                                            this.state.teacher &&
                                                            this.props.hasOwnProperty('instructionalFacilitatorBaseProps') &&
                                                            this.props.instructionalFacilitatorBaseProps &&
                                                            this.props.instructionalFacilitatorBaseProps.instructionalFacilitator &&
                                                            this.props.instructionalFacilitatorBaseProps.district ?
                                                            <div>
                                                                <CoachingNotes
                                                                    teacher={this.state.teacher}
                                                                    teacherId={this.state.teacher.id}
                                                                    districtId={this.props.instructionalFacilitatorBaseProps.district.id}
                                                                    authorId={this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.id}
                                                                    author={{
                                                                        firstName: this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.firstName,
                                                                        lastName: this.props.instructionalFacilitatorBaseProps.instructionalFacilitator.lastName,
                                                                    }}
                                                                />
                                                            </div>
                                                            : ''}

                                                        {this.state.menuKey === 'Coaching' &&
                                                            this.state.teacher &&
                                                            this.props.hasOwnProperty('adminBaseProps') &&
                                                            this.props.adminBaseProps &&
                                                            this.props.adminBaseProps.admin &&
                                                            this.props.adminBaseProps.district ?
                                                            <div>
                                                                <CoachingNotes
                                                                    teacher={this.state.teacher}
                                                                    teacherId={this.state.teacher.id}
                                                                    districtId={this.props.adminBaseProps.district.id}
                                                                    authorId={this.props.adminBaseProps.admin.id}
                                                                    author={{
                                                                        firstName: this.props.adminBaseProps.admin.firstName,
                                                                        lastName: this.props.adminBaseProps.admin.lastName,
                                                                    }}
                                                                />
                                                            </div>
                                                            : ''}

                                                        {/*this.state.menuKey === 'Assessments' ?
                                                            <div>
                                                                <div>Assessments</div>
                                                                <PacingGuideTeacherAssessmentsBase
                                                                    teacherBaseProps={{
                                                                        teacher: this.state.teacher,
                                                                        district: this.props.adminBaseProps.district,
                                                                    }}
                                                                    isAdmin={true}
                                                                    {...this.props}
                                                                />
                                                                </div> : ''*/}


                                                        {/*this.state.menuKey === 'Assessment Data' ?
                                                            <div>
                                                                {this.props.adminBaseProps &&
                                                                    this.props.adminBaseProps.district &&
                                                                    this.state.students ?
                                                                    <DistrictStatsV2
                                                                        district={this.props.adminBaseProps.district}
                                                                        queryId={this.state.teacher.id}
                                                                    />
                                                                    : ''}
                                                                </div> : ''*/}

                                                        {this.state.menuKey === 'Students' ?
                                                            <div className='w-680'>
                                                                <div className='mb-2 mt-2'>
                                                                    <h1 className='font-24 font-bold mb-2'>Students</h1>
                                                                </div>
                                                                {this.state.students ?
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Search for a student"
                                                                        className='w-100 mb-2'
                                                                        optionFilterProp="children"
                                                                        onSelect={(studentId) => {
                                                                            if (this.getAdminOrInstructionalFacilitator() && this.state.schoolId && this.state.teacherId && studentId) {
                                                                                this.props.history.push({
                                                                                    pathname: adminStudentUrl + this.getAdminOrInstructionalFacilitator().id + "?s=" + this.state.schoolId + '&t=' + this.state.teacherId + '&st=' + studentId
                                                                                })
                                                                            }
                                                                        }}
                                                                        size="large"
                                                                        filterOption={(input, option) => {
                                                                            let person = option.props.children.props.person
                                                                            let fullName = person.firstName + ' ' + person.lastName
                                                                            return fullName.toLowerCase().includes(input.toLowerCase())
                                                                        }}
                                                                    >
                                                                        {this.state.students &&
                                                                            this.state.students.map((student, idx) => {
                                                                                return <Select.Option value={student.id} key={'search-' + student.id}>
                                                                                    <PersonAvatar
                                                                                        person={student}
                                                                                        containerClassName="font-16"
                                                                                        avatarClassName="font-16 font-bold mr-2"
                                                                                        personClassName="font-black font-16"
                                                                                    />
                                                                                </Select.Option>
                                                                            })}
                                                                    </Select>
                                                                    : ''}

                                                                {this.state.students && this.state.students.length === 0 ?
                                                                    <div className='font-16 font-bold mb-1'>No students</div>
                                                                    : ''}
                                                                {this.state.students &&
                                                                    this.state.students.map((student, idx) => {
                                                                        return <Link to={adminStudentUrl + this.getAdminOrInstructionalFacilitator().id + "?s=" + this.state.schoolId + '&t=' + this.state.teacherId + '&st=' + student.id}
                                                                            id={student.id} key={student.id}>
                                                                            <div className={"flex flex-v-center p-1 br-20 background-fff mb-1 ant-shadow ant-shadow-more-hover parent-hover relative w-100 font-16"}>
                                                                                <PersonAvatar
                                                                                    person={student}
                                                                                    size={'large'}
                                                                                    containerClassName="font-20"
                                                                                    avatarClassName="font-20 font-bold mr-2"
                                                                                    personClassName="font-black font-20 ellipsis"
                                                                                    personStyle={{ maxWidth: '250px' }}
                                                                                />
                                                                                <div className='ml-auto'>
                                                                                    <StudentSummary student={student} />
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    })}
                                                            </div> : ''}
                                                    </div>

                                                </div>

                                                : ''}
                                        </div>
                                    </div>

                                    <div className='mt-50'>
                                        <CustomFooter />
                                    </div>
                                </Content>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </div >
        )
    }
}

export default AdminTeacherView